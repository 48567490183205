import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { EquipmentService } from '../shared/services/equipment.service';
import { SupportingDataService } from '../shared/services/supporting-data.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { MeterDetailsComponent } from '../details/meter/meter-details.component';
import { ControlSetDetailsComponent } from '../details/control-set/control-set-details.component';
import { BessDetailsComponent } from '../details/bess/bess-details.component';
import { TimezonesService } from '../shared/services/timezones.service';
import { LocalesService } from '../shared/services/locales.service';
import { MachineDetailsComponent } from '../details/machine/machine-details.component';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { GeneratorDetailsComponent } from '../details/generator/generator-details.component';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Formula, Parameter } from '../shared/model/formula.model';
import { EquipmentModelsService } from '../shared/services/equipment-models.service';
import { GroupDetailsComponent } from '../details/group/group-details.component';
import { EquipmentGroupService } from '../shared/services/equipment-group.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your equipment';
  REQUIRED = 'required';
  org = '';
  sites = [];
  spaceId;
  isSubmitting = false;
  equipmentTypesDropDown = [];
  selectedEquipmentType = 'METER';
  appData: any = {};
  postingEquipment = false;
  formulas: Formula[];
  multiLocaleConfig: LocaleBaseConfig = {
    locales: [new Locale()],
    supportedLocales: [new Locale()],
    defaultLocale: new Locale(),
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
    id: '',
  };
  allFilter: any = {
    key: 'equipment.group',
  };
  noEquipmentSelected = true;

  isEquipmentGroup: boolean;
  firstItemId: string;

  readonly mode = 'create';
  readonly APPPREFIX = 'eqp';
  private meterDetailsComponentView: MeterDetailsComponent;
  private controlSetDetailsComponentView: ControlSetDetailsComponent;
  private machineDetailsComponentView: MachineDetailsComponent;
  private bessDetailsComponentView: BessDetailsComponent;
  private generatorDetailsComponentView: GeneratorDetailsComponent;
  private groupDetailsComponentView: GroupDetailsComponent;

  @ViewChild(MeterDetailsComponent)
  set meterComponent(meterDetailsComponent: MeterDetailsComponent) {
    this.meterDetailsComponentView = meterDetailsComponent;
  }

  @ViewChild(ControlSetDetailsComponent)
  set ControlSetComponent(controlSetDetailsComponent: ControlSetDetailsComponent) {
    this.controlSetDetailsComponentView = controlSetDetailsComponent;
  }

  @ViewChild(MachineDetailsComponent)
  set MachineComponent(machineDetailsComponent: MachineDetailsComponent) {
    this.machineDetailsComponentView = machineDetailsComponent;
  }

  @ViewChild(BessDetailsComponent)
  set BessComponent(bessDetailsComponent: BessDetailsComponent) {
    this.bessDetailsComponentView = bessDetailsComponent;
  }

  @ViewChild(GeneratorDetailsComponent)
  set GeneratorComponent(generatorDetailsComponent: GeneratorDetailsComponent) {
    this.generatorDetailsComponentView = generatorDetailsComponent;
  }

  @ViewChild(GroupDetailsComponent)
  set GroupComponent(groupDetailsComponent: GroupDetailsComponent) {
    this.groupDetailsComponentView = groupDetailsComponent;
  }

  constructor(
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private messageService: GlobalAlertService,
    private equipmentService: EquipmentService,
    private supportingDataService: SupportingDataService,
    private orgSelectorService: ContextSelectorService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private equipmentModelService: EquipmentModelsService,
    private equipmentGroupService: EquipmentGroupService,
    private route: ActivatedRoute,
  ) {
    this.selectedEquipmentType = 'METER';
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.appData.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';

    const translateSub = this.translateService
      .get('equipment.notification.created_successfully')
      .subscribe((result: string) => {
        this.SUCCESS = result;
        this.BAD_REQUEST = this.translateService.instant('equipment.notification.bad_request');
        this.NOT_CREATED = this.translateService.instant('equipment.notification.not_created');
        this.REQUIRED = this.translateService.instant('equipment.validation.required');
      });

    const routeSub = this.route.params.subscribe(() => {
      if (this.route.queryParams['value']['type'] && this.route.queryParams['value']['type'] === 'EQUIPMENT_GROUP') {
        this.selectedEquipmentType = 'EQUIPMENT_GROUP';
        this.isEquipmentGroup = true;
      }
    });

    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }

    const currentContextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.appData.loadingSites = true;
      this.appData.sites = [];
      this.org = org.id;
      this.loadDynamicLists(org.id);
    });

    const formulasSub = equipmentModelService.formulas$.subscribe(formulas => {
      this.formulas = formulas;
    });

    const equipmentsTypesSub = this.equipmentService.equipmentTypesDropDown$.subscribe(equipmentTypesDropDown => {
      if (this.equipmentTypesDropDown.length === 0) {
        // Added due to an issue with Angular 9 resetting dropdown selections on second call
        this.equipmentTypesDropDown = equipmentTypesDropDown;
        this.appData.equipmentTypesDropDown = equipmentTypesDropDown;
      }
    });

    const sitesSub = this.equipmentService.sites$.subscribe(sites => {
      this.appData.sites = sites;
      this.appData.loadingSites = false;
    });

    const siteMetersSub = this.equipmentService.siteMeters$.subscribe(siteMeters => {
      this.appData.siteMeters = siteMeters;
    });

    const equipmentsLenght = this.equipmentGroupService.equipmentLength$.subscribe(length => {
      if (length > 0) {
        this.noEquipmentSelected = false;
      } else {
        this.noEquipmentSelected = true;
      }
    });

    const devicesSub = this.equipmentService.devices$.subscribe(devices => {
      this.appData.devices = devices;
    });

    const siteMachinesSub = this.equipmentService.siteMachines$.subscribe(siteMachines => {
      this.appData.siteMachines = siteMachines;
    });

    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.multiLocaleConfig.locales = locales;
      const locale = this.localesService.checkForLocale(this.appData.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.multiLocaleConfig.supportedLocales = [locale];
    });

    this.equipmentGroupService.equipmentGroups$.subscribe(async equipmentGroup => {
      if (equipmentGroup) {
        this.firstItemId = equipmentGroup[0]?.id;
      }
    });

    this.subscriptions.push(
      localesSub,
      siteMachinesSub,
      devicesSub,
      equipmentsLenght,
      siteMetersSub,
      sitesSub,
      equipmentsTypesSub,
      formulasSub,
      currentContextSub as unknown as Subscription,
      routeSub,
      translateSub,
    );

    this.localesService.setLocales();
  }

  async ngOnInit(): Promise<void> {
    const { equipmentType } = this.ngxDeeplinkerService.getReturnData(this.APPPREFIX);
    if (equipmentType) {
      this.selectedEquipmentType = equipmentType;
    }

    this.equipmentService.allSpaces$.subscribe(async equipmentGroup => {});
  }

  handleCancel() {
    let url = '';

    if (this.isEquipmentGroup) {
      url = `details/${this.firstItemId}/view`;
      this.router.navigate([url], { queryParams: { type: 'EQUIPMENT_GROUP' } });
    } else {
      this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }

  parseFormulaParameter(parameter: any, formulaParameter: Parameter) {
    if (formulaParameter.type === 'number') {
      return parseFloat(parameter);
    }
    if (formulaParameter.type === 'boolean') {
      return parameter === 'true';
    }
  }

  async handleSubmit() {
    try {
      this.isSubmitting = true;
      let equipmentModel = this.getModelForEquipmentType();
      equipmentModel = JSON.parse(JSON.stringify(equipmentModel));
      this.postingEquipment = true;
      const response = await this.equipmentService.createEquipment(equipmentModel);
      this.postingEquipment = false;
      this.equipmentService.ForceReloadNoCached();
      this.handleResponse(response);
    } catch (e) {
      if (e && e.error === 'ERR_BAD_REQUEST') {
        this.messageService.setError(this.BAD_REQUEST);
      } else {
        let errorAssigned = false;
        if (e.error && e.error.data) {
          for (const error of e.error.data.errors) {
            if (error.uncleError) {
              this.messageService.setError(error.display_label + ': ' + error.description);
              errorAssigned = true;
              break;
            } else {
              this.messageService.setError(this.NOT_CREATED + ':  ' + error.atlasMessage);
              errorAssigned = true;
              break;
            }
          }
        }
        if (!errorAssigned) {
          this.messageService.setError(this.NOT_CREATED);
        }
      }
      this.postingEquipment = false;
    }
  }

  async handleGroupSubmit() {
    this.isSubmitting = true;
    let equipmentModel = this.getModelForEquipmentType();
    const equipmentsSelected = this.equipmentGroupService.getEquipmentsSelected();
    equipmentModel = JSON.parse(JSON.stringify(equipmentModel));
    try {
      await this.createSpace(equipmentModel);
      if (equipmentsSelected) {
        await this.createChildren(equipmentsSelected, equipmentModel.parentId);
      }
      this.messageService.setSuccess(this.SUCCESS);
      const url = `details/${this.spaceId}/view`;
      this.equipmentService.ForceReloadNoCached();
      this.equipmentService.getOrgEquipment(this.org);
      this.equipmentGroupService.getOrgEquipmentGroups(this.org);
      setTimeout(() => {
        this.router.navigate([url], { queryParams: { type: 'EQUIPMENT_GROUP' } });
      }, 2000);
    } catch {
      this.messageService.setError(this.BAD_REQUEST);
    }
  }

  async createSpace(equipmentModel: any) {
    const space = await this.equipmentGroupService.createEquipmentGroup(equipmentModel);
    this.spaceId = space.id;
    await this.equipmentGroupService.createRelationships(equipmentModel.parentId, this.spaceId);
  }

  async createChildren(equipments, siteId) {
    for (const element of equipments) {
      await this.equipmentGroupService.deleteRelationships(siteId, element.id);
      await this.equipmentGroupService.createRelationships(this.spaceId, element.id);
    }
  }

  private getModelForEquipmentType() {
    let equipment: any = {};
    switch (this.selectedEquipmentType) {
      case 'CONTROL_SET':
        equipment = {
          ...this.controlSetDetailsComponentView.controlSet,
        };

        if (equipment.address === '') {
          delete equipment.address;
        }

        if (equipment.provider === '') {
          delete equipment.provider;
        }
        break;
      case 'MACHINERY':
        equipment = {
          ...this.machineDetailsComponentView.machine,
        };
        break;
      case 'BESS':
        equipment = {
          ...this.bessDetailsComponentView.bess,
        };
        break;
      case 'GENERATOR':
        equipment = {
          ...this.generatorDetailsComponentView.generator,
        };
        break;
      case 'EQUIPMENT_GROUP':
        equipment = {
          ...this.groupDetailsComponentView.group,
        };
        break;
      default:
        equipment = {
          ...this.meterDetailsComponentView.meter,
        };

        for (const point of equipment.points) {
          if (point.formulaId) {
            const formula = this.formulas.find((formulaTemp: Formula) => formulaTemp.id === point.formulaId);
            Object.keys(point.formulaParameters).forEach((param: any) => {
              point.formulaParameters[param] = this.parseFormulaParameter(
                point.formulaParameters[param],
                formula.parameters[param],
              );
            });
          } else {
            delete point.formulaId;
            delete point.formulaParameters;
          }

          if (typeof point.deliveredChannelId === 'number') {
            point.deliveredChannelId = point.deliveredChannelId.toString();
          }

          if (typeof point.channelId === 'number') {
            point.channelId = point.channelId.toString();
          }
        }

        if (equipment.operator === '-1') {
          delete equipment.operator;
        }

        if (equipment.marketMeterId === '') {
          delete equipment.marketMeterId;
        }

        equipment.displayLabels[this.appData.userLocale] = this.meterDetailsComponentView.meterName;
    }
    equipment.equipmentType = this.selectedEquipmentType;
    return equipment;
  }

  private handleResponse(response: any) {
    if (response.code === 207) {
      const equipmentId = response.data.id;
      // Handle equipment-group equipments
      this.postEquipmentsList(equipmentId);

      // partial-success
      if (response.errors) {
        let errorString = '';
        for (const error of response.errors) {
          errorString += error.message ? ' ' + error.message + '\n' : error.atlasMessage;
        }
        this.messageService.setError(errorString);
      } else {
        this.messageService.setError(this.BAD_REQUEST);
      }

      setTimeout(() => {
        this.router.navigate([`details/${equipmentId}/edit`], {});
      }, 2000);
    } else if (response.code === 200) {
      const equipmentId = response.data.id;
      // Handle equipment-group equipments
      this.postEquipmentsList(equipmentId);

      this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: equipmentId });
      this.messageService.setSuccess(this.SUCCESS);
      setTimeout(() => {
        this.equipmentService.refetchEquipment();
        this.router.navigate([`details/${equipmentId}/view`], {});
      }, 2000);
    } else if (response.id) {
      this.messageService.setSuccess(this.SUCCESS);
      setTimeout(() => {
        this.equipmentService.refetchEquipment();
        this.router.navigate([`details/${response.id}/view`], {});
      }, 2000);
    } else {
      this.messageService.setError(this.NOT_CREATED);
    }
  }

  private async loadDynamicLists(orgId: string) {
    this.equipmentService.setEquipmentTypesDropDown();
    this.supportingDataService.setOperators();
    this.timezonesService.setTimezones();
    this.equipmentService.setDevicesAndCommands();

    if (orgId) {
      this.equipmentService.setOrgEquipment(orgId);
    }
  }

  private postEquipmentsList(newEquipmentId: string) {
    let equipmentModel = this.getModelForEquipmentType();
    equipmentModel = JSON.parse(JSON.stringify(equipmentModel));

    if (equipmentModel.equipmentsList && equipmentModel.equipmentsList.length > 0) {
      let list = [...equipmentModel.equipmentsList];

      let parentIds: string[] = [];
      list.forEach((item: any) => {
        // Store parentId
        parentIds.push(item.parentId);
        try {
          item.parentId = newEquipmentId;
        } catch (e) {
          if (e && e.error === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            let errorAssigned = false;
            if (e.error && e.error.data) {
              for (const error of e.error.data.errors) {
                if (error.uncleError) {
                  this.messageService.setError(error.display_label + ': ' + error.description);
                  errorAssigned = true;
                  break;
                } else {
                  this.messageService.setError(this.NOT_CREATED + ':  ' + error.atlasMessage);
                  errorAssigned = true;
                  break;
                }
              }
            }
            if (!errorAssigned) {
              this.messageService.setError(this.NOT_CREATED);
            }
          }
          this.postingEquipment = false;
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
