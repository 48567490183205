import { BasicList } from 'src/app/details/equipment-list/basic-list';

export let statuses = [
  {
    id: 'ACTIVE',
    display_label: 'equipment.create.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'equipment.create.inactive',
  },
];

export interface Meter {
  site: any;
  id: string;
  displayLabel: string;
  displayLabels: any;
  marketMeterId: string;
  classicNodeId: string;
  operator: string;
  siteId: string;
  description: string;
  descriptions: any;
  defaultLocale: string;
  timezone: string;
  equipmentType: string;
  points: PointInterface[];
}

export interface PointInterface {
  channelId: string;
  deliveredChannelId: string;
  dataProvider: string;
  descriptions: any;
  displayLabels: any;
  description: string;
  displayLabel: string;
  expressionFactor: number;
  follows: any[];
  isCumulative: boolean;
  isActive: boolean;
  reportingInterval: number;
  multiplier: number;
  sourceId: string;
  type: string;
  veeGapCheck: boolean;
  veeHighReadCheck: boolean;
  veeNegativeReadCheck: boolean;
  veeZeroCheck: boolean;
  formulaId: string;
  formulaParameters: Map<string, number>;
}

export class Point {
  channelId = '';
  deliveredChannelId = '';
  dataProvider = '-1';
  description = '';
  descriptions = {};
  displayLabel = '';
  displayLabels = {};
  expressionFactor = 1.0;
  follows = [];
  isCumulative = true;
  isActive = true;
  reportingInterval = -1;
  type = 'MeasuredPoint';
  multiplier = 1.0;
  veeBypass = false;
  veeGapCheck = true;
  veeHighReadCheck = true;
  veeNegativeReadCheck = true;
  veeZeroCheck = false;
  veeHighReadThreshold: number = 50000;
  sourceId = '';
  source = '';
  spaceType = '';
  formulaId = '';
  formulaParameters = new Map<string, number>();
  isDynamic: boolean = false;
  constructor(isDynamic: boolean = false) {
    this.isDynamic = isDynamic;
  }
}

export interface ControlSet {
  id: string;
  displayLabel: string;
  displayLabels: any;
  address: string;
  provider: string;
  site: any;
  siteId: string;
  description: string;
  descriptions: any;
  defaultLocale: string;
  timezone: string;
  equipmentType: string;
  deviceType: any;
  controlElements: ControlElement[];
}

export class ControlElement {
  displayLabel = 'Control Element Name';
  equipmentType = 'CONTROLELEMENT';
  controlElementName: '';
  controlType: 'BINARY';
  description = '';
  availableCommands = [];
  controls = [];
  displayLabels = {};
  descriptions = {};
  constructor() {}
}

export interface Equipment {
  equipmentType: string;
  id: string;
  displayLabel: string;
  displayLabels: any;
  organization: any;
}

export interface BaseEquipment {
  id: string;
  displayLabel: string;
  displayLabels: any;
  site: any;
  organization: any;
  siteId: string;
  description: string;
  descriptions: any;
  defaultLocale: string;
  timezone: string;
  equipmentType: string;
}

export interface Bess {
  id: string;
  displayLabel: string;
  displayLabels: any;
  site: any;
  siteId: string;
  description: string;
  descriptions: any;
  defaultLocale: string;
  timezone: string;
  equipmentType: string;
  derosBessId: string;
}

export interface EquipmentType {
  name: string;
  displayLabel: string;
  isSelected: boolean;
}

export interface Device {
  displayLabel: string;
  deviceType: string;
  availableCommands: AvailableCommands;
}

export interface AvailableCommands {
  name: string;
  displayLabel: string;
}
export class DefaultVEESettings {
  veeGapCheck = true;
  veeHighReadCheck = true;
  veeNegativeReadCheck = true;
  veeZeroCheck = false;
  veeHighReadThreshold = 50000;
}

export class Group {
  id: string;
  equipmentType: string;
  defaultLocale: string;
  description: string;
  descriptions: any;
  displayLabel: string;
  displayLabels: any; // equipment group name
  shortDisplaylabels: any;
  status: string;
  timezone: string;
  parent: any;
  parentId: string;
  // spaceType: string;
  // coreSpaceType: string;
  equipmentIds: any;
}
