<div class="dual-list">
    <div class="listbox" [ngStyle]="{ order: direction() ? 1 : 2, 'margin-left': direction() ? 0 : '10px' }">
        <h3 class="list-title" data-automation="available-equipment">{{ 'group.equipment.parent' | translate }}</h3>
        <span class="inform" data-automation="add-group">{{ 'group.equipment.addGroup' | translate }}</span>

        <div class="container-list">
            <form *ngIf="filter" class="filter">
                <input
                    type="text"
                    name="filterSource"
                    [(ngModel)]="available.picker"
                    (ngModelChange)="onFilter(available)"
                    class="filterInput"
                    placeholder="{{ 'group.equipment.search' | translate }}"
                />
            </form>
            <div class="record-picker">
                <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
                    <li
                        *ngFor="let item of getFilteredAvailableSift(); let idx = index"
                        (click)="disabled ? null : moveItem(available, confirmed, item, false, 'right')"
                    >
                        <mat-icon class="tree-icon">{{ getIconByType(item) }}</mat-icon>
                        <div
                            class="equipment-list-item"
                            [attr.data-automation]="'available_equipment_' + item.displayLabel"
                        >
                            {{ item.displayLabel }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <button
            class="btn-all"
            [attr.data-automation]="'addAll'"
            (click)="moveEquipment(available, confirmed, 'right')"
        >
            <span>{{ 'group.equipment.addAll' | translate }}</span>
        </button>
    </div>

    <div class="listbox" [ngStyle]="{ order: direction() ? 2 : 1, 'margin-left': direction() ? '10px' : 0 }">
        <h3 class="list-title" data-automation="selected-equipment">{{ 'group.equipment.selected' | translate }}</h3>
        <span class="inform" data-automation="remove-group">{{ 'group.equipment.removeGroup' | translate }}</span>

        <div class="container-list">
            <div class="record-picker-right">
                <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
                    <li
                        #itmConf
                        *ngFor="let item of confirmed.sift; let idx = index"
                        (click)="disabled ? null : moveItem(confirmed, available, item, false, 'left')"
                    >
                        <mat-icon class="tree-icon">{{ getIconByType(item) }}</mat-icon>
                        <div
                            class="equipment-list-item"
                            [attr.data-automation]="'selected_equipment_' + item.displayLabel"
                        >
                            {{ item.displayLabel }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <button
            class="btn-all"
            [attr.data-automation]="'removeAll'"
            (click)="moveEquipment(confirmed, available, 'left')"
        >
            <span>{{ 'group.equipment.removeAll' | translate }}</span>
        </button>
    </div>
</div>
