<h2 mat-dialog-title class="delete-confirmation-title">
    {{ 'equipment.delete.confirmation.title' | translate }} {{ data.displayLabel }}?
</h2>

<mat-dialog-content class="delete-text">
    <ng-container [ngSwitch]="data.equipmentType" class="delete-confirmation-content">
        <ng-container *ngSwitchDefault data-automation="deleteTitle" class="delete-text">{{ data.displayLabel }}{{ 'equipment.delete.confirmation.will_be_removed' | translate }}</ng-container>
        <ng-container *ngSwitchCase="'machinery'" data-automation="deleteTitle" class="delete-text">{{ data.displayLabel }}{{ 'equipment.delete.confirmation.will_be_removed_no_children' | translate }}</ng-container>
        <div data-automation="deleteText" class="delete-text">{{ 'equipment.delete.confirmation.undone' | translate }}</div>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="x-btn-secondary dialog-secondary" (click)="close()" data-automation="deleteCancel">
        {{ 'equipment.delete.confirmation.cancel' | translate }}
    </button>
    <button
        class="x-btn-primary dialog-primary"
        (click)="delete()"
        [disabled]="deletingEquipment"
        data-automation="confirmDeleteButton"
    >
        {{ 'equipment.delete.confirmation.delete' | translate }}
    </button>
</mat-dialog-actions>
