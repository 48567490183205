<div class="app-equipment-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingEquipment">edit</mat-icon>
                    <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>

                <h1 id="edit-title" data-automation="editTitle" *ngIf="isEquipment">
                    <ng-container *ngIf="!loadingEquipment">{{ getDisplayLabel() }}</ng-container>
                    <ng-container *ngIf="loadingEquipment">{{ 'equipment.loading' | translate }}</ng-container>
                </h1>

                <h1 id="edit-group-title" data-automation="editGroupTitle" *ngIf="isEquipmentGroup">
                    <ng-container *ngIf="!loadingEquipmentGroup">{{ getGroupDisplayLabel() }}</ng-container>
                    <ng-container *ngIf="loadingEquipmentGroup">{{ 'equipment.loading' | translate }}</ng-container>
                </h1>

                <p *ngIf="equipment && isEquipment" class="desktop-only subtitle" data-automation="subtitle">
                    <ng-container *ngIf="equipment.equipmentType === 'METER'">{{
                        'equipment.edit.meter.subtitle' | translate
                    }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'CONTROL_SET'">{{
                        'equipment.edit.controlSet.subtitle' | translate
                    }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'MACHINERY'">{{
                        'equipment.edit.machine.subtitle' | translate
                    }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'BESS'">{{
                        'equipment.edit.bess.subtitle' | translate
                    }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'GENERATOR'">{{
                        'equipment.edit.generator.subtitle' | translate
                    }}</ng-container>
                </p>

                <p *ngIf="isEquipmentGroup" class="desktop-only subtitle" data-automation="equipmentGroupSubtitle">
                    <ng-container> {{ equipmentGroup_subtitle | translate }} </ng-container>
                </p>
            </div>
        </div>

        <div class="right-column">
            <button class="icon-btn x-btn-secondary" (click)="handleCancel()" data-automation="equipCancel">
                <mat-icon>clear</mat-icon>
                <span class="btn-text">{{ 'equipment.view.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="delete"
                data-automation="equipDeleteButton"
                (click)="openDeleteConfirmation()"
                [disabled]="!equipment && !equipmentGroup"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'equipment.delete.confirmation.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                (click)="handleSubmit()"
                data-automation="equipSubmit"
                [disabled]="!edit.valid || saving"
            >
                <mat-icon *ngIf="!postingEquipment">done</mat-icon>
                <div *ngIf="postingEquipment" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'equipment.edit.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #edit="ngForm" class="equipment-form">
        <ng-container *ngIf="equipment">
            <ng-container [ngSwitch]="equipmentType">
                <ng-container *ngSwitchCase="'METER'">
                    <app-meter
                        [appData]="appData"
                        [mode]="mode"
                        [meter]="equipment"
                        [LocaleConfig]="multiLocaleConfig"
                    ></app-meter>
                </ng-container>
                <ng-container *ngSwitchCase="'CONTROL_SET'">
                    <app-control-set
                        [appData]="appData"
                        [mode]="mode"
                        [controlSet]="equipment"
                        [LocaleConfig]="multiLocaleConfig"
                    ></app-control-set>
                </ng-container>
                <ng-container *ngSwitchCase="'MACHINERY'">
                    <app-machine
                        [appData]="appData"
                        [mode]="mode"
                        [machine]="equipment"
                        [LocaleConfig]="multiLocaleConfig"
                    ></app-machine>
                </ng-container>
                <ng-container *ngSwitchCase="'BESS'">
                    <app-bess
                        [appData]="appData"
                        [mode]="mode"
                        [bess]="equipment"
                        [LocaleConfig]="multiLocaleConfig"
                    ></app-bess>
                </ng-container>
                <ng-container *ngSwitchCase="'GENERATOR'">
                    <app-generator
                        [appData]="appData"
                        [mode]="mode"
                        [generator]="equipment"
                        [LocaleConfig]="multiLocaleConfig"
                    ></app-generator>
                </ng-container>
                <ng-container *ngSwitchDefault class="alert alert-warning"
                    >Equipment type not yet supported.</ng-container
                >
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isEquipmentGroup">
            <app-group
                [appData]="appData"
                [equipmentgroup]="equipmentGroup"
                [mode]="mode"
                [LocaleConfig]="multiLocaleConfig"
            ></app-group>
        </ng-container>
    </form>
</div>
