import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { EquipmentModel } from '../model/equipment-model.model';
import { Formula } from '../model/formula.model';

@Injectable()
export class EquipmentModelsService {
  equipmentModels$: BehaviorSubject<EquipmentModel[]> = new BehaviorSubject<EquipmentModel[]>([]);
  formulas$: BehaviorSubject<Formula[]> = new BehaviorSubject<Formula[]>([]);

  constructor(private apiService: ApiService) {
    this.setEquipmentModels();
    this.setFormulas();
  }

  async setEquipmentModels() {
    const equipmentModels = await this.apiService.get('equipment-models');
    this.equipmentModels$.next(convertKeys.toCamel(equipmentModels));
  }

  async setFormulas() {
    const formulas = await this.apiService.get('formulas');
    this.formulas$.next(convertKeys.toCamel(formulas));
  }
}
