import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AvailableCommands, ControlElement, Meter } from '../../shared/model/equipment.model';
import { LocaleBaseConfig } from '../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { FormValidatorService } from '../../shared/services/form-validator.service';

@Component({
  selector: 'app-control-elements',
  templateUrl: './control-element-details.component.html',
  styleUrls: ['./control-element-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ControlElementDetailsComponent implements OnInit {
  panelOpenedState: boolean;
  loadingSiteMeters: boolean;
  localeConfig: LocaleBaseConfig;
  namePlaceholder = '';
  nameLabel = '';
  descriptionLabel = '';
  descriptionPlaceholder = '';
  displayLabelsId = 'element_display_labels';
  descriptionsId = 'element_descriptions';

  @Output() addControlSetElement: EventEmitter<any> = new EventEmitter();
  @Output() deleteControlSetElement: EventEmitter<any> = new EventEmitter();
  @Output() editControlSetElement: EventEmitter<any> = new EventEmitter();

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';
  private _mode: string;
  private _control: ControlElement;
  private _controlTypes: any[];
  private _siteMeters: Meter[];
  private _deviceCommands: AvailableCommands;
  private _data: any;
  private _index: number;
  private _count: number;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set control(control: any) {
    this._control = control;
    if (!this._control.controlType) {
      this._control.controlType = 'BINARY';
    }
  }

  get control() {
    return this._control;
  }

  @Input()
  set data(data: any) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  @Input()
  set multiLocaleConfig(config: any) {
    this.localeConfig = config;
  }

  @Input()
  set controlTypes(controlTypes: any) {
    this._controlTypes = controlTypes;
  }

  get controlTypes() {
    return this._controlTypes;
  }

  @Input()
  set siteMeters(siteMeters: any) {
    this._siteMeters = siteMeters;
  }

  get siteMeters() {
    if (this._data.siteMeters && this._data.siteId) {
      this.loadingSiteMeters = this._data.loadingSiteMachines;
      this._siteMeters = this._data.siteMeters[this._data.siteId];
    }
    return this._siteMeters;
  }

  @Input()
  set deviceCommands(deviceCommands: any) {
    if (this._deviceCommands && JSON.stringify(this._deviceCommands) !== JSON.stringify(deviceCommands)) {
      this.resetCommands();
    }
    this._deviceCommands = deviceCommands;
  }

  get deviceCommands() {
    return this._deviceCommands;
  }

  @Input()
  set index(index: any) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input()
  set count(count: any) {
    this._count = count;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabel() {
    if (this._control) {
      return this._control.displayLabel;
    }
  }

  get canDelete() {
    return this._count > 1;
  }

  constructor(
    private translateService: TranslateService,
    private formValidatorService: FormValidatorService,
    private form: NgForm,
  ) {
    this.panelOpenedState = false;
    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('sharedFields.create.placeholder.name');
    this.descriptionLabel = this.translateService.instant('sharedFields.create.description');
    this.descriptionPlaceholder = this.descriptionLabel;
  }

  handleEdit(e: Event) {
    e.stopImmediatePropagation();
    this.editControlSetElement.emit();
  }

  addControl(e: Event) {
    e.stopImmediatePropagation();
    this.addControlSetElement.emit();
  }

  deleteControl(e: Event, index: number) {
    e.stopImmediatePropagation();
    this.deleteControlSetElement.emit(index);
  }

  getControlType() {
    if (this._control.controlType && this._controlTypes.length > 0) {
      return this._controlTypes.find((type: any) => this.control.controlType === type.name).displayLabel;
    }
  }

  removeSelectedMeter(meterId: string) {
    this._control.controls = this._control.controls.filter((controlElement) => {
      return controlElement !== meterId;
    });
  }

  removeSelectedCommand(commandId: string) {
    this._control.availableCommands = this._control.availableCommands.filter((command) => {
      return command !== commandId;
    });
  }

  resetCommands() {
    this._control.availableCommands = [];
  }

  getMeterDisplayLabel(meterId: string) {
    if (this.siteMeters && this.siteMeters.length > 0 && this._control.controls.length > 0) {
      return this.siteMeters.find((meter) => meter.id === meterId).displayLabel;
    }
  }

  getAvailableCommandsDisplayLabel(commandId: string) {
    if (this.deviceCommands && this.deviceCommands.length > 0) {
      return this.deviceCommands.find((command) => command.name === commandId).displayLabel;
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this._control.equipmentType) {
      return this._data.equipmentTypes.filter((e: any) => e.name === this._control.equipmentType)[0].displayLabel;
    }
  }

  getDisplayLabelsId() {
    return `${this.displayLabelsId}_${this.index}`;
  }

  getDescriptionsId() {
    return `${this.descriptionsId}_${this.index}`;
  }

  async ngOnInit() {}

  expandPanel() {
    this.formValidatorService.triggerValidation(this.form.form);
    this.panelOpenedState = true;
  }
}
