import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentService } from '../shared/services/equipment.service';
import { EquipmentGroupService } from '../shared/services/equipment-group.service';
import { EquipmentDetailsService } from '../shared/services/equipment-details.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noOrgEquipment = false;
  noOrgEquipmentGroups = false;
  orgEquipment;
  orgEquipmentGroups;
  subs = [];

  constructor(
    private router: Router,
    private equipmentService: EquipmentService,
    private equipmentGroupService: EquipmentGroupService,
    private equipmentDetailsService: EquipmentDetailsService,
    private organizationsService: OrganizationsService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
  }
  navigateToFirstEquipment() {
    const firstChild = this.getFirstEquipment();
    if (this.orgEquipment && this.orgEquipment.length > 0 && firstChild != null) {
      const id = firstChild.id;
      this.loading = true;
      this.noOrgEquipment = false;
      this.noOrgEquipmentGroups = false;
      if(firstChild.equipmentType === 'EQUIPMENT_GROUP') {
        setTimeout(() => {
          this.router.navigate([`details/${id}/view`], {
            queryParams: { type: 'EQUIPMENT_GROUP' },
          });
        }, 2000);
      }
      else {
        setTimeout(() => {
          this.router.navigate([`details/${id}/view`], {});
        }, 2000);
      }
    } else {
      this.loading = false;
      this.noOrgEquipment = true;
      this.noOrgEquipmentGroups = true;
    }
  }

  getFirstEquipment() {
    const sitesWithChildren = this.orgEquipment.filter(site => {
      return site.children && site.children.length > 0
    });
    if(sitesWithChildren.length === 0) {
      return null;
    }
    return sitesWithChildren[0].children[0];
  }

  navigateToFirstEquipmentGroup() {
    if (this.orgEquipmentGroups && this.orgEquipmentGroups.length > 0) {
      this.loading = true;
      this.noOrgEquipmentGroups = false;
      setTimeout(() => {
        this.router.navigate([`details/${this.orgEquipmentGroups[0].id}/view`], {
          queryParams: { type: 'EQUIPMENT_GROUP' },
        });
      }, 2000);
    } else {
      this.loading = false;
      this.noOrgEquipmentGroups = true;
    }
  }

  async ngOnInit() {
    const orgEquipSub = this.equipmentService.orgEquipment$.subscribe(orgEquipment => {
      if (this.router.isActive('/', true)) {
        this.orgEquipment = orgEquipment;
        this.navigateToFirstEquipment();
      }
    });
    const orgEquipGroupSub = this.equipmentGroupService.equipmentGroups$.subscribe(groups => {
      if (this.router.isActive('/?type=EQUIPMENT_GROUP', true)) {
        this.orgEquipmentGroups = groups;
        this.navigateToFirstEquipmentGroup();
      }
    });

    this.subs.push(orgEquipSub);
    this.subs.push(orgEquipGroupSub);
  }

  async ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
