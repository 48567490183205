<div id="equipmentGroupForm" ngModelGroup="equipmentGroupForm">

    <div class="form-wrapper">

        <div class="form-entry">
            <span class="info-title">{{ 'equipmentGroup.equipment_type' | translate }}:</span>
            <div class="locale-value info-value" data-automation="equipmentType">
              {{ equipmentType }}
            </div>
        </div>

        <div class="form-entry">
            <span class="info-title">{{ 'sharedFields.create.parent' | translate }}:</span>
            <div class="info-value"  data-automation="parent">
                {{ getParentDispalyLabel() }}
            </div>
            <mat-label class="info-value info-placeHolder" *ngIf="!equipmentGroup">
                {{ 'equipmentGroup.placeholder.no_parent' | translate }}
            </mat-label>
        </div>

        <div class="form-entry">
            <span class="info-title">{{ 'sharedFields.create.timezone' | translate }}:</span>
            <div class="timezone-value info-value" data-automation="timezone">
               {{ getTimezoneForEquipmentGroup().displayLabel }}
            </div>
        </div>

        <div class="form-entry">
            <span class="info-title" data-automation="defaultLocale" >{{ 'sharedFields.create.default_locale' | translate }}:</span>
            <div class="info-value">
               {{ getLocaleForEquipmentGroup().displayLabel }}
            </div>
        </div>

        <div class="form-entry">
            <span class="info-title">{{ 'sharedFields.create.status' | translate }}:</span>
            <div class="info-value"  data-automation="status">
                {{ getStatusForEquipmentGroup() }}
            </div>
            <mat-label class="info-value info-placeHolder" *ngIf="!equipmentGroup">
                {{ 'equipmentGroup.placeholder.no_status' | translate }}
            </mat-label>
        </div>

    </div>

    <h3 *ngIf="equipmentGroup" class="points-title form-wrapper" data-automation="viewTitle">
        {{ 'equipmentGroup.view.equipment' | translate }}
    </h3>

    <hr class="form-divider" />
    <div *ngIf="loadingEquipment" class="btn-spinner points-title">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="equipment-list" *ngIf="equipmentGroup && !loadingEquipment" >
        <ng-container >
            <kendo-treeview
            [nodes]="dataSource"
            textField="displayLabel"
            [children]="children"
            [hasChildren]="hasChildren"
            data-automation="locationTreeSelector"
            class="kendoChildList"
            >
            <ng-template data-type="dataItem.type" kendoTreeViewNodeTemplate let-dataItem >
                <ng-container *ngIf="dataItem">
                    <mat-icon class="tree-icon">{{ getIconByType(dataItem) }}</mat-icon>
                    <div class="equipment-list-item" (click)="onSelectEquipment(dataItem)" data-automation="equipmentChild">
                        {{ dataItem.displayLabel }}
                    </div>
                </ng-container>
            </ng-template>
        </kendo-treeview>

        </ng-container>
    </div>




</div>
