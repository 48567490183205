<div class="app-equipment-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 *ngIf="selectedEquipmentType !== 'EQUIPMENT_GROUP'" id="create-title" data-automation="createTitle">
                    {{ 'equipment.create.page_title' | translate }}
                </h1>
                <p *ngIf="selectedEquipmentType !== 'EQUIPMENT_GROUP'" class="subtitle" data-automation="subtitle">
                    {{ 'equipment.create.subtitle' | translate }}
                </p>
                <h1 *ngIf="selectedEquipmentType === 'EQUIPMENT_GROUP'" id="create-title" data-automation="createTitle">
                    {{ 'equipment.create.equipmentGroupTitle' | translate }}
                </h1>
                <p *ngIf="selectedEquipmentType === 'EQUIPMENT_GROUP'" class="subtitle" data-automation="subtitle">
                    {{ 'equipment.create.equipmentGroupSubtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="equipCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text">{{ 'equipment.create.button.cancel' | translate }}</span>
            </button>

            <button
                *ngIf="
                    selectedEquipmentType === 'METER' ||
                    selectedEquipmentType === 'CONTROL_SET' ||
                    selectedEquipmentType === 'MACHINERY' ||
                    selectedEquipmentType === 'BESS' ||
                    selectedEquipmentType === 'GENERATOR' ||
                    selectedEquipmentType === 'EQUIPMENT_GROUP'
                "
                class="icon-btn x-btn-primary"
                data-automation="equipSubmit"
                type="submit"
                (click)="selectedEquipmentType === 'EQUIPMENT_GROUP' ? handleGroupSubmit() : handleSubmit()"
                [disabled]="createForm.invalid || isSubmitting"
            >
                <mat-icon *ngIf="!postingEquipment">done</mat-icon>
                <div *ngIf="postingEquipment" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'equipment.create.button.submit' | translate }}</span>
            </button>
        </div>
    </header>
    <form #createForm="ngForm" class="equipment-create-form">
        <div class="form-wrapper">
            <div class="form-entry equipment-type">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'equipment.create.equipment_type' | translate }}</mat-label>
                    <mat-select
                        id="equipmentType"
                        name="equipmentType"
                        #equipmentType="ngModel"
                        [ngClass]="{
                            failure: equipmentType.invalid && (equipmentType.dirty || equipmentType.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="selectedEquipmentType"
                        data-automation="equipmentTypeSelector"
                        data-url-persist
                        [value]="selectedEquipmentType"
                    >
                        <mat-option
                            *ngFor="let equipType of equipmentTypesDropDown"
                            [value]="equipType.name"
                            id="equipType_{{ equipType.name }}"
                            >{{ equipType.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <ng-container [ngSwitch]="selectedEquipmentType">
            <ng-container *ngSwitchCase="'METER'">
                <app-meter [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-meter>
            </ng-container>
            <ng-container *ngSwitchCase="'CONTROL_SET'">
                <app-control-set [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-control-set>
            </ng-container>
            <ng-container *ngSwitchCase="'MACHINERY'">
                <app-machine [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-machine>
            </ng-container>
            <ng-container *ngSwitchCase="'BESS'">
                <app-bess [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-bess>
            </ng-container>
            <ng-container *ngSwitchCase="'GENERATOR'">
                <app-generator [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-generator>
            </ng-container>
            <ng-container *ngSwitchCase="'EQUIPMENT_GROUP'">
                <app-group [mode]="mode" [appData]="appData" [LocaleConfig]="multiLocaleConfig"></app-group>
            </ng-container>
            <ng-container *ngSwitchDefault class="alert alert-warning">Equipment type not yet supported.</ng-container>
        </ng-container>
    </form>
</div>
