import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { Operator } from '../model/space.model';

@Injectable()
export class SupportingDataService {
  dataProviders$: BehaviorSubject<any[]>;
  operators$: BehaviorSubject<Operator[]>;
  channels$: BehaviorSubject<any[]>;
  localeSelectorChanged$: BehaviorSubject<boolean>;
  reportingIntervals$: BehaviorSubject<any[]>;
  applyDataProviderToAllPoints$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private data = new Subject<any>();
  public data$ = this.data.asObservable();

  private channelIdsDisabledByDefault: string[] = ['268'];
  private measuredWhitelist: string[] = [];
  
  constructor(private apiService: ApiService) {
    this.dataProviders$ = new BehaviorSubject<any>([]);
    this.operators$ = new BehaviorSubject<Operator[]>([]);
    this.channels$ = new BehaviorSubject<any[]>([]);
    this.localeSelectorChanged$ = new BehaviorSubject<any>(false);
    this.reportingIntervals$ = new BehaviorSubject<any[]>([]);
  }

  emitdata(x: any){
    this.data.next(x);
  }
  async setDataProviders(id: string) {
    try {
      const dataProviders = await this.apiService.get(`data-providers/${id}`);
      this.dataProviders$.next(dataProviders.map(convertKeys.toCamel));
    } catch (err) {
      console.log('Could not fetch data providers ', err);
    }
  }

  async setOperators() {
    try {
      const operators = await this.apiService.get(`operators`);
      this.operators$.next(operators.map(convertKeys.toCamel));
    } catch (err) {
      console.log('Could not load Operators.', err);
    }
  }

  async getChannels() {
    try {
      const channels = await this.apiService.get('channel-categories');
      this.channels$.next(convertKeys.toCamel(channels));
    } catch (err) {
      console.log('Could not load channels.', err);
    }
  }

  getMeasuredChannels() {
    if(this.channels$.getValue()) {
      return this.channels$.getValue().filter(channel => this.measuredWhitelist.includes(channel.id));
    }
    return [];
  }

  getChannelsDisabledByDefault(){
    return this.channelIdsDisabledByDefault.slice();
  }

  async getLocaleSelectorChanged() {
      this.localeSelectorChanged$.next(true);
  }

  async setReportingIntervals() {
    try {
      const reportingIntervals = await this.apiService.get('granularities');
      this.reportingIntervals$.next(convertKeys.toCamel(reportingIntervals));
    } catch (err) {
      console.log('Could not load reporting intervals.', err);
    }
  }
  async fetchChannelWhitelists() {
    try {
      const response = await this.apiService.get('point-channel-whitelists');
      const whitelists: any[] = convertKeys.toCamel(response);
      if(whitelists) {
        this.measuredWhitelist = whitelists.find(whitelist => whitelist.pointType == "MEASURED_POINT").channels;
      }
    } catch (err) {
      console.log('Could not load channel whitelists.', err);
    }
  }
}
