import { Component, EventEmitter, Input, Output, OnInit, OnDestroy  } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ControlElement, DefaultVEESettings, Meter, Point } from '../../shared/model/equipment.model';
import { SupportingDataService } from '../../shared/services/supporting-data.service';
import { Router } from '@angular/router';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocaleBaseConfig } from '../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { FormValidatorService } from '../../shared/services/form-validator.service';

@Component({
  selector: 'app-measured-points',
  templateUrl: './measured-point-details.component.html',
  styleUrls: ['./measured-point-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MeasuredPointDetailsComponent implements OnInit, OnDestroy {
  channels: any[];
  channelIdsDisabledByDefault: string[];
  panelOpenedState: boolean;
  usedChannels: any[] = [];
  loadingPointTypes = true;
  loadingChannels = true;
  loadingReportingIntervals = true;
  loadingSiteMachines: boolean;
  selectedSiteId = '';
  selectedMeterName = '';
  localeConfig: LocaleBaseConfig;
  hideVee = true;
  parentMeter: Meter;
  meterDisplayLabels: any;
  userLocale = 'en_US';
  namePlaceholder: string = '';
  nameLabel: string = '';
  pointNamePlaceholder: string = '';
  displayLabelsId = 'point_display_labels';
  applyDataProviderToAllPoints: boolean;
  subscriptions: Subscription[] = [];
  parameters = [];
  @Output() addDynamicPoint: EventEmitter<any> = new EventEmitter();
  @Output() deleteDynamicPoint: EventEmitter<any> = new EventEmitter();
  @Output() updateSelectedChannels: EventEmitter<any> = new EventEmitter();
  @Output() updateSourceIds: EventEmitter<any> = new EventEmitter();

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';
  private _mode: string;
  private _point: Point;
  private _appData: any;
  private _pointTypes: any[];
  private _reportingIntervals: any[];
  private _index: number;
  private _count: number;
  private _selectedChannels: any[];
  private canonicalChannels: any[];
  private _sourceIds: any[];
  private _siteMachines: any;
  private dataProviders: any[];
  private parentDataProvider: string;
  newPoint: boolean = false;

  @Input() loadingDataProviders: boolean = false;

  areChannelsReady: boolean = false;

  JSON: any = JSON;
  firstTime: boolean = true;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set point(point: any) {
    this._point = point;
    if (this._point.spaceType && !this._point.type) {
      this._point.type = this._point.spaceType;
    }
    if (typeof point.dataProvider === 'string') {
      this._point.dataProvider = point.dataProvider;
    } else {
      this._point.dataProvider = (point.dataProvider as any).id;
    }
  }

  get point() {
    return this._point;
  }

  @Input()
  set meterName(meterName: string) {
    this.selectedMeterName = meterName;
    this.updateDisplayLabel(this.point.deliveredChannelId,null);
  }

  @Input()
  set meter(meter: Meter) {
    this.parentMeter = meter;
    this.meterDisplayLabels = meter.displayLabels;
  }

  @Input()
  set multiLocaleConfig(config: any) { 
    this.localeConfig = config;
  }

  @Input()
  set siteId(siteId: string) {
    this.selectedSiteId = siteId;
  }

  @Input()
  set appData(appData: any) {
    this._appData = appData;
    this.userLocale = appData.userLocale;
  }

  get appData() {
    return this._appData;
  }

  @Input()
  set selectedDataProvider(provider: string) {
    this.parentDataProvider = provider;
    if (this.applyDataProviderToAllPoints && this.isCreateMode) {
      this.updateVeeSettings(this.parentDataProvider);
      this.point.dataProvider = this.parentDataProvider;
    }
  }

  @Input()
  set hideVeeSettings(hideVee: boolean) {
    this.hideVee = hideVee;
  }

  @Input()
  set pointTypes(pointTypes: any) {
    if (pointTypes.length > 0) {
      this.loadingPointTypes = false;
    }
    this._pointTypes = pointTypes;
  }

  get pointTypes() {
    return this._pointTypes;
  }

  @Input()
  set reportingIntervals(reportingIntervals: any) {
    if (reportingIntervals.length > 0) {
      this.loadingReportingIntervals = false;
    }
    this._reportingIntervals = reportingIntervals;
  }

  get reportingIntervals() {
    return this._reportingIntervals;
  }

  @Input()
  set index(index: any) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input()
  set selectedChannels(selectedChannels: any) {
    this._selectedChannels = selectedChannels;
    this.updateCanonicalChannels();
  }

  get selectedChannels() {
    return this._selectedChannels;
  }

  @Input()
  set count(count: any) {
    this._count = count;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  @Input()
  set sourceIds(sourceIds: any) {
    this._sourceIds = sourceIds;
  }

  get sourceIds() {
    return this._sourceIds;
  }

  get siteMachines() {
    if (this._appData.siteMachines && this._appData.siteId) {
      this.loadingSiteMachines = this._appData.loadingSiteMachines;
      this._siteMachines = this._appData.siteMachines[this._appData.siteId];
    }
    return this._siteMachines;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabel() {
    if (this.point.displayLabels) {
      return this.point.displayLabels[this.userLocale];
    }
    return this.point.displayLabel;
  }

  get pointChannel() {
    if (this._point.deliveredChannelId && this.channels.length > 0) {
      const selectedChannel = this.channels.find((channel: any) => this._point.deliveredChannelId + '' === channel.id);
      if (selectedChannel) {
        return selectedChannel.displayLabel;
      }
    }
  }

  get pointSourceId() {
    if (this._point) {
      return this._point.sourceId || this._point.source;
    }
  }

  get pointType() {
    if (this._point.spaceType && this._pointTypes.length > 0) {
      return this._pointTypes.find((type: any) => this._point.spaceType === type.id).displayLabel || '';
    }
  }

  get canDelete() {
    return this._count > 1;
  }

  constructor(
    private supportingDataService: SupportingDataService,
    private router: Router,
    private ngxCookieService: NgxCookieService,
    private translateService: TranslateService,
    private formValidatorService: FormValidatorService,
    private form: NgForm
  ) {
    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('sharedFields.create.placeholder.name');
    this.pointNamePlaceholder = this.translateService.instant('measuredPoint.create.placeholder.point_name');
    const channelsSub = this.supportingDataService.channels$.subscribe(channels => {
      this.channels = this.supportingDataService.getMeasuredChannels();
      if (channels.length > 0) {
        this.loadingChannels = false;
      }
    });
    const dataProviderSelectionMethodSub = this.supportingDataService.applyDataProviderToAllPoints$.subscribe(
      applyToAll => {
        this.applyDataProviderToAllPoints = applyToAll;
        if (applyToAll && this.point && this.isCreateMode) {
          this.point.dataProvider = this.parentDataProvider;
        }
      },
    );

    this.panelOpenedState = false;
    this.subscriptions.push(...[channelsSub, dataProviderSelectionMethodSub]);
  }

  handleEdit(e: Event) {
    e.stopImmediatePropagation();
    if (this.parentMeter) {
      const { id } = this.parentMeter;
      this.router.navigate([`details/${id}/edit`]);
    }
  }

  addPoint(e: Event) {
    this.newPoint = true;
    e.stopImmediatePropagation();
    this.addDynamicPoint.emit();
    this.updateSourceIds.emit();
  }

  deletePoint(e: Event, index: number) {
    e.stopImmediatePropagation();
    this.deleteDynamicPoint.emit(index);
    this.updateSourceIds.emit();
  }

  onMeasuringChange($event) {
    this.updateUsedChannels($event.value);
    this.updateChannelId($event.value);
    this.updateDisplayLabel($event.value,null);
  }

  updateUsedChannels(channelId: string) {
    if (!this.usedChannels || !this.usedChannels.includes(channelId)) {
      this.updateSelectedChannels.emit();
    }
  }

  updateChannelId(deliveredChannelId: string) {
    let canonicalChannel = this.channels.find(channel => {
      if (channel.isCanonical === true && channel.conversions && channel.conversions.inboundConversions) {
        const requiredChannelIds = channel.conversions.inboundConversions.reduce((acc, currentConversion) => {
          return [...acc, ...currentConversion.requiredChannelIds];
        }, []);
        return requiredChannelIds.includes(deliveredChannelId);
      }
    });
    this._point.channelId = canonicalChannel?.id.toString() || deliveredChannelId.toString();
  }

  updateCanonicalChannels() {
    let canonicalChannels = [];
    this.channels.forEach(channel => {
      if (channel.isCanonical === true && channel.conversions && channel.conversions.inboundConversions) {
        const requiredChannelIds = channel.conversions.inboundConversions.reduce((acc, currentConversion) => {
          return [...acc, ...currentConversion.requiredChannelIds];
        }, []);
        this.selectedChannels.forEach(selectedChannel => {
          if (!this.point.deliveredChannelId || selectedChannel !== this.point.deliveredChannelId.toString()) {
            if (channel.id === selectedChannel || requiredChannelIds.includes(selectedChannel)) {
              canonicalChannels.push(...requiredChannelIds);
              canonicalChannels.push(channel.id);
            }
          }
        });
      }
    });
    this.canonicalChannels = Array.from(new Set(canonicalChannels));        
  }

 
  updateDisplayLabel(channelId,isFirstTime) {
    let displayLabel = this.pointNamePlaceholder;
    if (this.isViewMode || ((isFirstTime || this.firstTime) && channelId !== '-1')) {
      this.firstTime = false;
      if(this._point.displayLabel !== ""){
        displayLabel = this._point.displayLabel;
        return;
      } else {
        this._point.displayLabels[this.userLocale] = displayLabel;
      }
    }
    this.firstTime = false;
    this.selectedMeterName = this.selectedMeterName ? this.selectedMeterName.trim() : '';
    if (this.selectedMeterName) {
      displayLabel =  this.selectedMeterName;
      this.formValidatorService.isDisplayLabelDirty(this.form.form)
    } else {
      displayLabel = 'Point';
    }
    
    if (channelId !== '-1' && this.channels) {
      const channel = this.channels.find(channel => channel.id == channelId);
      if (channel) {
        displayLabel = `${displayLabel} - ${channel.displayLabel}`;
      }
    } 
    this._point.displayLabels[this.userLocale] = displayLabel;
  }



  checkExistingPointChannels(channelId: string) {
    return (
      (this.selectedChannels && this.selectedChannels.includes(channelId)) ||
      (this.canonicalChannels && this.canonicalChannels.includes(channelId)) ||
      this.channelIdsDisabledByDefault.includes(channelId)
    )
  }

  removeSelectedMachine(machineId: string) {
    this._point.follows = this._point.follows.filter(machine => {
      return machine !== machineId;
    });
  }

  getEquipment(equipmentId: string | ControlElement) {
    let equipment: any;
    if (this.siteMachines) {
      let keys = Object.keys(this.siteMachines);
      for (let key of keys) {
        equipment = this.siteMachines[key].find(siteMachine => equipmentId === siteMachine.id);
        if (equipment) {
          break;
        }
      }
      return equipment;
    }
  }

  followsMachines() {
    return this._point.follows.length > 0;
  }

  veeBypassFromPoint() {
    return this._point.veeBypass;
  }

  channelCompare(channel1: any, channel2: any) {
    if (typeof channel1 === 'string') {
      channel1 = parseInt(channel1);
    }

    if (typeof channel2 === 'string') {
      channel2 = parseInt(channel2);
    }
    return channel1 === channel2;
  }

  reportingIntervalCompare(interval1: any, interval2: any) {
    if (typeof interval1 === 'string') {
      interval1 = parseInt(interval1);
    }

    if (typeof interval2 === 'string') {
      interval2 = parseInt(interval2);
    }
    return interval1 === interval2;
  }

  typeCompare(type1: any, type2: any) {
    return type1 && type2 ? type1 === type2 : false;
  }

  getPointReportingInterval() {
    if (this._reportingIntervals.length > 0 && this._point) {
      const reportingInterval = this._reportingIntervals.find(
        (interval: any) => this._point.reportingInterval == interval.durationInMilliseconds,
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }

      return '';
    }
    return '';
  }

  getDisplayLabelsId() {
    return `${this.displayLabelsId}_${this.index}`;
  }

  getSelectedDataProviderLabel() {
    if (this._point.dataProvider && this.dataProviders && this.dataProviders.length > 0) {
      const provider = this.dataProviders.find(provider => provider.id === this._point.dataProvider);
      if (provider) {
        return provider.displayLabel;
      }
      return '';
    }

    return '';
  }

  isEquipmentInGroup(equipmentId: string, group: any[]) {
    return !!group.find(equip => equipmentId === equip.id);
  }

  onMeterTypeChange(event: MatSelectChange) {
    console.log(event)
  }

  async ngOnInit() {
    this.supportingDataService.dataProviders$.subscribe(dataProviders => {
      this.dataProviders = dataProviders;
      const dataProvider = dataProviders.find(provider => {
        return provider.id == this._point.dataProvider;
      });
      if (dataProvider && !this._point.veeBypass && this.mode === this.EDIT) {
        const veeSettings = new DefaultVEESettings();
        for (const prop in veeSettings) {
          this._point[prop] =
            this._point[prop] === null || this._point[prop] === undefined ? veeSettings[prop] : this._point[prop];
        }
      }
    });

    await this.supportingDataService.fetchChannelWhitelists();
    await this.supportingDataService.getChannels();
    const localSelectorSub = this.supportingDataService.localeSelectorChanged$.subscribe(data => {
      if (data) {
        this.form.form.controls[`localeInputsForm_point_display_labels_${this.index}`].markAsDirty();
      }
    });
    if (this.channels) {
      this.areChannelsReady = true;
      this.updateDisplayLabel(this.point.deliveredChannelId, true);
    }
    this.subscriptions = [...this.subscriptions, localSelectorSub];

    this.channelIdsDisabledByDefault = this.supportingDataService.getChannelsDisabledByDefault();
  
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  handleDataProviderChange($event: MatSelectChange) {
    this.updateVeeSettings($event.value);
  }

  updateVeeSettings(dataProviderId) {
    if (this.dataProviders && this.dataProviders.length > 0) {
      const dataProvider = this.dataProviders.find(provider => {
        return provider.id === dataProviderId;
      });

      if (dataProvider) {
        this.hideVee = dataProvider.veeBypassDefault;
      }
    }
  }
  onChangeInput(event:any) {
    if (event.target.value) {
      this.formValidatorService.isDisplayLabelDirty(this.form.form);
    }
  }
  expandPanel() {   
    this.panelOpenedState = true;
    this.formValidatorService.triggerValidation(this.form.form);
  }
}
