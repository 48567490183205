import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Context } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';

import { SidenavService } from './shared/services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { NavService } from './shared/services/global-nav.service';

import { MatOptionSelectionChange } from '@angular/material/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { EquipmentService } from './shared/services/equipment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  orgList: Context[] = [];
  sidenavOpen = true;
  navUrl = '';
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  equipmentOptions = [
    { key: 'equipment', displayedLabel: '' },
    { key: 'equipment_group', displayedLabel: '' },
  ];
  selectedEquipmentOption = 'equipment';

  constructor(
    private translate: TranslateService,

    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private navService: NavService,

    private equipmentService: EquipmentService,
    changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    media: MediaMatcher,
  ) {
    this.getNavUrl();
    this.mobileQuery = media.matchMedia('(max-width: 1280px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.equipmentOptions[0].displayedLabel = this.translate.instant('equipment.option.equipment');
    this.equipmentOptions[1].displayedLabel = this.translate.instant('equipment.option.equipment_group');
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.type === 'EQUIPMENT_GROUP') {
        this.selectedEquipmentOption = 'equipment_group';
        this.equipmentService.selectedType$.next(this.selectedEquipmentOption);
      }
    });
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  triggerResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  handleEquipmentOption(event: MatOptionSelectionChange) {
    if (!event.isUserInput) {
      return;
    }
    this.selectedEquipmentOption = event.source.value;
    this.equipmentService.selectedType$.next(this.selectedEquipmentOption);
  }
}
