import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { EquipmentService } from '../../shared/services/equipment.service';
import { EquipmentGroupService } from '../../shared/services/equipment-group.service';
import { GlobalAlertService } from '../../shared/services/global-alert.service';
import { Router } from '@angular/router';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit {
  SUCCESS = ' deleted successfully.';
  ERROR = 'Something went wrong. Please try again.';
  deletingEquipment = false;
  org = '';

  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private equipmentService: EquipmentService,
    private equipmentGroupService: EquipmentGroupService,
    private messageService: GlobalAlertService,
    private router: Router,
    private orgSelectorService: ContextSelectorService,
    private translateService: TranslateService,
  ) {
    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.org = org.id;
    });

    this.translateService.get('sharedFields.delete.notification.success').subscribe((result: string) => {
      this.SUCCESS = result;
      this.ERROR = this.translateService.instant('sharedFields.delete.notification.error');
    });
  }

  ngOnInit() {}

  async delete() {
    this.deletingEquipment = true;
    try {
      if (this.data.isGroup) {
        await this.equipmentGroupService.deleteEquipmentGroup(this.data.id);
      } else {
        await this.equipmentService.deleteEquipment(this.data.id, this.data.equipmentType);
      }
      this.equipmentService.ForceReloadNoCached();
      this.equipmentService.getOrgEquipment(this.org);
      this.messageService.setSuccess(this.data.displayLabel + this.SUCCESS);
      this.deletingEquipment = false;
      this.dialogRef.close();
      setTimeout(() => { this.router.navigate(['/'], { queryParamsHandling: 'preserve' }); }, 500);
    } catch (err) {
      this.deletingEquipment = false;
      this.messageService.setError(this.ERROR);
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
