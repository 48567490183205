import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChange,
  EventEmitter,
  IterableDiffers,
  DoCheck,
} from '@angular/core';
import { EQUIPMENTGROUP_ICON_DEFINITION } from 'src/app/shared/model/nodeType';
import { ORG_ICON_DEFINITION } from 'src/app/shared/model/nodeType.model';
import { EquipmentGroupService } from 'src/app/shared/services/equipment-group.service';
import { BasicList } from './basic-list';
import * as convertKeys from 'convert-keys';

export type compareFunction = (a: any, b: any) => number;

let nextId = 0;

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
})
export class EquipmentListComponent implements OnInit, OnChanges, DoCheck {
  static AVAILABLE_LIST_NAME = 'available';
  static CONFIRMED_LIST_NAME = 'confirmed';
  iconDefinitions: any = { ...ORG_ICON_DEFINITION, ...EQUIPMENTGROUP_ICON_DEFINITION };

  static LTR = 'left-to-right';
  static RTL = 'right-to-left';

  static DEFAULT_FORMAT = {
    add: 'Add',
    remove: 'Remove',
    all: 'All',
    none: 'None',
    direction: EquipmentListComponent.LTR,
  };

  available: BasicList;
  confirmed: BasicList;

  sourceDiffer: any;
  destinationDiffer: any;
  private sorter = (a: any, b: any) => {
    return a.displayLabel < b.displayLabel ? -1 : a.displayLabel > b.displayLabel ? 1 : 0;
  };

  @Input() id = `dual-list-${nextId++}`;
  @Input() key = 'id';
  @Input() display = 'displayLabel';
  @Input() height = '100px';
  @Input() filter = false;
  @Input() format = EquipmentListComponent.DEFAULT_FORMAT;
  @Input() sort = false;
  @Input() compare: compareFunction;
  @Input() disabled = false;
  @Input() source: any[];
  @Input() destination: BasicList;
  @Input() presenter: any;
  @Output() destinationChange = new EventEmitter();
  @Output() movedItem = new EventEmitter<boolean>();

  _equipmentgroup: any;
  allEquipmentGroupsWithChildren: any;
  preSelectedList: any[] = [];
  newConfirmed: any[] = [];

  constructor(private differs: IterableDiffers, private equipmentGroupService: EquipmentGroupService) {
    this.available = new BasicList(EquipmentListComponent.AVAILABLE_LIST_NAME);
    this.confirmed = new BasicList(EquipmentListComponent.CONFIRMED_LIST_NAME);

    this.allEquipmentGroupsWithChildren = this.equipmentGroupService.getGroupListResult();

    this.source = [...this.preSelectedList];
  }

  @Input()
  set equipmentgroup(equipmentgroup: any) {
    this._equipmentgroup = equipmentgroup;
  }

  get equipmentgroup() {
    return this._equipmentgroup;
  }

  ngOnInit(): void {
    if (this.allEquipmentGroupsWithChildren && this.equipmentgroup) {
      this.preSelectedList = this.findEquipmentGroup(this.allEquipmentGroupsWithChildren, this.equipmentgroup.id);
    }
    this.confirmed.sift = this.preSelectedList || [];
    this.confirmed.list = this.preSelectedList || [];
    this.equipmentGroupService.setInitialState(this.preSelectedList);
    this.equipmentGroupService.resetItemsList();
  }

  findEquipmentGroup(equipmentWithChildren, idToFind) {
    let equipmentUnchecked = [...equipmentWithChildren];
    let currentEquipment;
    while (equipmentUnchecked.length > 0) {
      currentEquipment = equipmentUnchecked.pop();
      if (currentEquipment?.id === idToFind) {
        currentEquipment = convertKeys.toCamel(currentEquipment);
        return currentEquipment.children;
      } else if (currentEquipment.children) {
        equipmentUnchecked.push(...currentEquipment.children);
      }
    }
    return [];
  }

  ngOnChanges(changeRecord: { [key: string]: SimpleChange }) {
    if (changeRecord['filter']) {
      if (changeRecord['filter'].currentValue === false) {
        this.clearFilter(this.available);
        this.clearFilter(this.confirmed);
      }
    }

    if (changeRecord['sort']) {
      if (changeRecord['sort'].currentValue === true && this.compare === undefined) {
        this.compare = this.sorter;
      } else if (changeRecord['sort'].currentValue === false) {
        this.compare = undefined;
      }
    }

    if (changeRecord['format']) {
      this.format = changeRecord['format'].currentValue;

      if (typeof this.format.direction === 'undefined') {
        this.format.direction = EquipmentListComponent.LTR;
      }

      if (typeof this.format.add === 'undefined') {
        this.format.add = EquipmentListComponent.DEFAULT_FORMAT.add;
      }

      if (typeof this.format.remove === 'undefined') {
        this.format.remove = EquipmentListComponent.DEFAULT_FORMAT.remove;
      }

      if (typeof this.format.all === 'undefined') {
        this.format.all = EquipmentListComponent.DEFAULT_FORMAT.all;
      }

      if (typeof this.format.none === 'undefined') {
        this.format.none = EquipmentListComponent.DEFAULT_FORMAT.none;
      }
    }

    if (changeRecord['source']) {
      this.available = new BasicList(EquipmentListComponent.AVAILABLE_LIST_NAME);
      this.updatedSource();
      this.updatedDestination();
    }

    if (changeRecord['destination']) {
      this.confirmed = new BasicList(EquipmentListComponent.CONFIRMED_LIST_NAME);
      this.updatedDestination();
      this.updatedSource();
    }
  }

  ngDoCheck() {
    if (this.source && this.buildAvailable(this.source)) {
      this.onFilter(this.available);
    }
    if (this.destination.list && this.buildConfirmed(this.destination)) {
      this.onFilter(this.confirmed);
    }
  }

  buildAvailable(source: any[]): boolean {
    const sourceChanges = this.sourceDiffer.diff(source);
    if (sourceChanges) {
      sourceChanges.forEachRemovedItem((r: any) => {
        const idx = this.findItemIndex(this.available.list, r.item, this.key);
        if (idx !== -1) {
          this.available.list.splice(idx, 1);
        }
      });

      sourceChanges.forEachAddedItem((r: any) => {
        // Do not add duplicates even if source has duplicates.
        if (this.findItemIndex(this.available.list, r.item, this.key) === -1) {
          this.available.list.push(r.item);
        }
      });

      if (this.compare !== undefined) {
        this.available.list.sort(this.compare);
      }
      this.available.sift = this.available.list;

      return true;
    }
    return false;
  }

  buildConfirmed(destination: BasicList): boolean {
    let moved = false;
    const destChanges = this.destinationDiffer.diff(destination.list);
    if (destChanges) {
      destChanges.forEachRemovedItem((r: any) => {
        const idx = this.findItemIndex(this.confirmed.list, r.item, this.key);
        if (idx !== -1) {
          if (!this.isItemSelected(this.confirmed.pick, this.confirmed.list[idx])) {
            this.selectItem(this.confirmed.pick, this.confirmed.list[idx]);
          }
          this.moveItem(this.confirmed, this.available, this.confirmed.list[idx], false, 'left');
          moved = true;
        }
      });

      destChanges.forEachAddedItem((r: any) => {
        const idx = this.findItemIndex(this.available.list, r.item, this.key);
        if (idx !== -1) {
          if (!this.isItemSelected(this.available.pick, this.available.list[idx])) {
            this.selectItem(this.available.pick, this.available.list[idx]);
          }
          this.moveItem(this.available, this.confirmed, this.available.list[idx], false, 'right');
          moved = true;
        }
      });

      if (this.compare !== undefined) {
        this.confirmed.list.sort(this.compare);
      }
      this.confirmed.sift = this.confirmed.list;

      if (moved) {
        this.trueUp();
      }
      return true;
    }
    return false;
  }

  updatedSource() {
    this.available.list.length = 0;
    this.available.pick.length = 0;
    if (this.source !== undefined) {
      this.sourceDiffer = this.differs.find(this.source).create(null);
    }
  }

  updatedDestination() {
    if (this.destination.list !== undefined) {
      this.destinationDiffer = this.differs.find(this.destination.list).create(null);
    }
  }

  direction() {
    return this.format.direction === EquipmentListComponent.LTR;
  }

  clearFilter(source: BasicList) {
    if (source) {
      source.picker = '';
      this.onFilter(source);
    }
  }

  getFilteredAvailableSift() {
    return this.available.sift.filter(item => {
      if (item.spaceType === 'Spacegroup' && item.coreSpaceType !== 'EQUIPMENT') {
        return false;
      }
      return true;
    });
  }

  onFilter(source: BasicList) {
    if (source.picker.length > 0) {
      const filtered = source.list.filter((item: any) => {
        if (Object.prototype.toString.call(item) === '[object Object]') {
          if (item._name !== undefined) {
            return item._name.toLowerCase().indexOf(source.picker.toLowerCase()) !== -1;
          } else {
            return (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(source.picker.toLowerCase()) !== -1
            );
          }
        } else {
          return item.toLowerCase().indexOf(source.picker.toLowerCase()) !== -1;
        }
      });
      source.sift = filtered;
      this.unpick(source);
    } else {
      source.sift = source.list;
    }
  }

  findItemIndex(list: any[], item: any, key: any = 'id') {
    let idx = -1;

    function matchObject(e: any) {
      if (e[key] === item[key]) {
        idx = list.indexOf(e);
        return true;
      }
      return false;
    }

    function match(e: any) {
      if (e[key] === item) {
        idx = list.indexOf(e);
        return true;
      }
      return false;
    }

    // Assumption is that the arrays do not have duplicates.
    if (typeof item === 'object') {
      list.filter(matchObject);
    } else {
      list.filter(match);
    }

    return idx;
  }

  isItemSelected(list: any[], item: any) {
    if (list.filter(e => Object.is(e, item)).length > 0) {
      return true;
    }
    return false;
  }

  selectItem(list: any[], item: any) {
    const pk = list.filter((e: any) => {
      return Object.is(e, item);
    });
    if (pk.length > 0) {
      // Already in list, so deselect.
      for (let i = 0, len = pk.length; i < len; i += 1) {
        const idx = list.indexOf(pk[i]);
        if (idx !== -1) {
          list.splice(idx, 1);
        }
      }
    } else {
      list.push(item);
    }
  }

  moveItem(source: BasicList, target: BasicList, item: any = null, trueup = true, direction) {
    let sourceIndex = 0;
    let sourceLength = source.sift.length;

    if (item) {
      sourceIndex = source.list.indexOf(item);
      sourceLength = sourceIndex + 1;
    }

    while (sourceIndex < sourceLength) {
      // Is the pick still in list?
      let movedItems: any[] = [];
      if (item) {
        const sourceItemIndex = this.findItemIndex(source.sift, item, this.key);
        if (sourceItemIndex !== -1) {
          movedItems[0] = source.sift[sourceItemIndex];
        }
      } else {
        movedItems = source.list.filter(src => {
          const _id = source.sift[sourceIndex][this.key];
          return _id ? src[this.key] === _id : false;
        });
      }

      // Should only ever be 1
      if (movedItems.length === 1) {
        // Add if not already in target.
        if (target.list.filter(trg => trg[this.key] === movedItems[0][this.key]).length === 0) {
          target.list.push(movedItems[0]);
        }
        this.makeUnavailable(source, movedItems[0]);
      }
      sourceIndex += 1;
    }

    if (this.compare !== undefined) {
      target.list.sort(this.compare);
    }

    source.pick.length = 0;

    // Update destination
    if (trueup) {
      this.trueUp();
    }

    // Delay ever-so-slightly to prevent race condition.
    setTimeout(() => {
      this.onFilter(source);
      this.onFilter(target);
    }, 10);
    if (direction === 'left') {
      this.equipmentGroupService.removeItemFromGroup(item);
      this.equipmentGroupService.setEquipmentsSelected(source.list);
    } else {
      this.equipmentGroupService.addItemToGroup(item);
      this.equipmentGroupService.setEquipmentsSelected(target.list);
    }
    this.movedItem.emit(true);
  }

  private unpick(source: BasicList) {
    for (let sourceIndex = source.pick.length - 1; sourceIndex >= 0; sourceIndex -= 1) {
      if (source.sift.indexOf(source.pick[sourceIndex]) === -1) {
        source.pick.splice(sourceIndex, 1);
      }
    }
  }

  private makeId(item: any): string | number {
    if (typeof item === 'object') {
      return item[this.key];
    } else {
      return item;
    }
  }

  // Allow for complex names by passing an array of strings.
  // Example: [display]="[ '_type.substring(0,1)', '_name' ]"
  private makeName(item: any): string {
    const display = this.display;

    function fallback(itm: any) {
      switch (Object.prototype.toString.call(itm)) {
        case '[object Number]':
          return itm;
        case '[object String]':
          return itm;
        default:
          if (itm !== undefined) {
            return itm[display];
          } else {
            return 'undefined';
          }
      }
    }

    let str = '';

    if (this.display !== undefined) {
      if (Object.prototype.toString.call(this.display) === '[object Array]') {
        for (let i = 0; i < this.display.length; i += 1) {
          if (str.length > 0) {
            str = str + '_';
          }

          if (this.display[i].indexOf('.') === -1) {
            // Simple, just add to string.
            str = str + item[this.display[i]];
          } else {
            // Complex, some action needs to be performed
            const parts = this.display[i].split('.');

            const s = item[parts[0]];
            if (s) {
              // Use brute force
              if (parts[1].indexOf('substring') !== -1) {
                const nums = parts[1].substring(parts[1].indexOf('(') + 1, parts[1].indexOf(')')).split(',');

                switch (nums.length) {
                  case 1:
                    str = str + s.substring(parseInt(nums[0], 10));
                    break;
                  case 2:
                    str = str + s.substring(parseInt(nums[0], 10), parseInt(nums[1], 10));
                    break;
                  default:
                    str = str + s;
                    break;
                }
              } else {
                // method not approved, so just add s.
                str = str + s;
              }
            }
          }
        }
        return str;
      } else {
        return fallback(item);
      }
    }

    return fallback(item);
  }

  private trueUp() {
    let changed = false;

    // Clear removed items.
    let pos = this.destination.list.length;
    while ((pos -= 1) >= 0) {
      const mv = this.confirmed.list.filter(confirmed => {
        if (typeof this.destination.list[pos] === 'object') {
          return confirmed[this.key] === this.destination.list[pos][this.key];
        } else {
          return confirmed[this.key] === this.destination.list[pos];
        }
      });
      if (mv.length === 0) {
        // Not found so remove.
        this.destination.list.splice(pos, 1);
        changed = true;
      }
    }

    // Push added items.
    for (let confirmedIndex = 0, len = this.confirmed.list.length; confirmedIndex < len; confirmedIndex += 1) {
      let movedItems = this.destination.list.filter((destinationItem: any) => {
        if (typeof destinationItem === 'object') {
          return destinationItem[this.key] === this.confirmed.list[confirmedIndex][this.key];
        } else {
          return destinationItem === this.confirmed.list[confirmedIndex][this.key];
        }
      });

      if (movedItems.length === 0) {
        // Not found so add.
        movedItems = this.source.filter((sourceItem: any) => {
          if (typeof sourceItem === 'object') {
            return sourceItem[this.key] === this.confirmed.list[confirmedIndex][this.key];
          } else {
            return sourceItem === this.confirmed.list[confirmedIndex][this.key];
          }
        });

        if (movedItems.length > 0) {
          this.destination.list.push(movedItems[0]);
          changed = true;
        }
      }
    }

    if (changed) {
      this.destinationChange.emit(this.destination.list);
    }
  }

  private makeUnavailable(source: BasicList, item: any) {
    const idx = source.list.indexOf(item);
    if (idx !== -1) {
      source.list.splice(idx, 1);
    }
  }

  getIconByType(event) {
    if (!event || !event.spaceType) {
      return '';
    }
    event =
      event.spaceType === 'Spacegroup' || event.spaceType === 'Equipmentgroup'
        ? event.spaceType.toLowerCase()
        : event.equipmentType.toLowerCase();
    event = event === 'BESS' ? 'Bess' : event;
    if (this.iconDefinitions[event] && this.iconDefinitions[event].name) {
      return this.iconDefinitions[event].name;
    }
    return '';
  }

  moveEquipment(source, destination, direction) {
    const length = source.list.length;
    for (let counter = 0; counter < length; counter++) {
      this.moveItem(source, destination, source.list[0], false, direction);
    }
  }
}
