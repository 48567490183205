import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AvailableCommands, ControlElement, ControlSet, Device, Meter } from '../../shared/model/equipment.model';
import { EquipmentService } from '../../shared/services/equipment.service';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Operator } from '../../shared/model/space.model';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { LocalesService } from 'src/app/shared/services/locales.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-control-set',
  templateUrl: './control-set-details.component.html',
  styleUrls: ['./control-set-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ControlSetDetailsComponent implements OnInit {
  timezones: Timezone[];
  operators: Operator[];
  controlTypes: any[];
  devices: Device[];
  deviceCommands: AvailableCommands | [];
  siteMeters: Meter[];

  // multi locale config
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';
  siteIdToDisplay: string = '';

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  private _mode: string;
  private _controlSet: ControlSet;
  private _data: any;

  constructor(
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private router: Router,
    private equipmentService: EquipmentService,
    private translateService: TranslateService,
  ) {
    this.timezones = this.timezonesService.timezones;
    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('controlSet.create.placeholder.control_set_name');
    this.descriptionLabel = this.translateService.instant('sharedFields.create.description');
    this.descriptionPlaceholder = this.descriptionLabel;

    const controlSet: Partial<ControlSet> = {
      siteId: '-1',
      defaultLocale: '',
      timezone: 'America/New_York',
      controlElements: [new ControlElement()],
      displayLabels: {},
      descriptions: {},
    };

    this.equipmentService.controlTypes$.subscribe(controlTypes => {
      this.controlTypes = controlTypes;
    });

    this.equipmentService.setControlTypes();

    this.populateControlSet(controlSet);
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set controlSet(controlSet: ControlSet) {
    this._controlSet = controlSet;
    if (controlSet) {
      this.buildSupportedLocales();
    }
  }

  get controlSet() {
    this.setDeviceTypeCommands();
    if (this._controlSet.site && this._data) {
      this._data.siteId = this._controlSet.site.id;
      this._controlSet.siteId = this._controlSet.site.id;
    }

    return this._controlSet;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
    this._controlSet.defaultLocale = appData.userLocale;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get descriptionExists() {
    return this._controlSet.description;
  }

  get addressExists() {
    return this._controlSet.address;
  }

  populateControlSet(controlSetData) {
    this._controlSet = controlSetData;
  }

  getTimezoneForControlSet() {
    if (this.timezones.length > 0) {
      return (
        this.timezones.find(timezone => this._controlSet.timezone.indexOf(timezone.timezoneName) !== -1).displayLabel ||
        new Timezone().displayLabel
      );
    }

    return new Timezone();
  }

  getLocaleForControlSet() {
    if (this.multiLocaleConfig.locales.length > 0) {
      const localeResult = this.multiLocaleConfig.locales.find(
        locale => this.controlSet.defaultLocale === locale.localeName,
      );
      if (localeResult) {
        return localeResult.displayLabel;
      } else {
        return;
      }
    }

    return new Locale();
  }

  resetProvider(event) {
    if (event.target.value === '') {
      this.controlSet.provider = '';
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this._data.equipmentTypes.length > 0 && this._controlSet) {
      return this._data.equipmentTypes.find(
        (equipmentType: any) => equipmentType.name === this._controlSet.equipmentType,
      ).displayLabel;
    }
  }

  getDeviceTypeDisplayLabel() {
    if (this._data.devices && this._data.devices.length > 0 && this._controlSet) {
      this.setDeviceTypeCommands();
      return this._data.devices.find((device: Device) => device.deviceType === this._controlSet.deviceType)
        .displayLabel;
    }
  }

  updateAvailableCommands($event) {
    const selectedDevice: Device = this._data.devices.find((device: Device) => device.deviceType === $event.value);
    this.deviceCommands = selectedDevice ? selectedDevice.availableCommands : [];
  }

  getSiteforControlSet() {
    if (this._controlSet) {
      return this._controlSet.site.displayLabel;
    }
  }

  handleEdit() {
    if (this._controlSet) {
      this.router.navigate([`details/${this._controlSet.id}/edit`]);
    }
  }

  hasControlElements() {
    return this._controlSet.controlElements.length > 0;
  }

  addControlSetElement() {
    this._controlSet.controlElements.push(new ControlElement());
  }

  deleteControlSetElement(index: number) {
    const newPoints: any[] = [];
    this._controlSet.controlElements.forEach((controlElement, elementIndex) => {
      if (index !== elementIndex) {
        newPoints.push(controlElement);
      }
    });

    this._controlSet.controlElements = [];
    setTimeout(() => {
      this._controlSet.controlElements = newPoints;
    }, 250);
  }

  setDeviceTypeCommands() {
    if (this.appData && this.appData.devices && this.appData.devices.length > 0 && this._controlSet.deviceType) {
      const deviceIndex = this.appData.devices.findIndex((device: Device) => {
        return device.deviceType === this._controlSet.deviceType;
      });
      this.deviceCommands = this.appData.devices[deviceIndex].availableCommands;
    }
  }

  updateSiteMeters(siteId) {
    this.siteMeters = this.appData.siteMeters[siteId];
  }

  onSiteChange({ value: siteId }) {
    this._data.siteId = siteId;
    this.updateSiteMeters(siteId);
    const selectedSite = this._data.sites.find(site => siteId === site.id);
    if (selectedSite) {
      this.localesService.useSitesDefaultLocaleAndTimezone(selectedSite, this.controlSet, this.multiLocaleConfig);
      this.siteIdToDisplay = selectedSite.displayLabel;
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this._data.equipment && this._data.equipment.displayLabels) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipment.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  ngOnInit() {}
}
