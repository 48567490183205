import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  constructor() {}

  hasRequiredDirtyFields(frmGrp: UntypedFormGroup) {
    let formIsDirty = false;

    if (!frmGrp || (frmGrp.pristine && frmGrp.invalid)) {
      return false;
    }

    for (let controlKey in frmGrp.controls) {
      const control: any = frmGrp.get(controlKey);
      try {
        if (typeof control.controls === 'undefined') {
          if (this.isRequiredField(control)) {
            if (this.isFieldDirty(control)) {
              formIsDirty = true;
              break;
            }
          }
        } else {
          // has controls need to recursively look at controls
          if (this.hasRequiredDirtyFields(control as UntypedFormGroup)) {
            formIsDirty = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return formIsDirty;
  }

  isRequiredField(control: AbstractControl) {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  isFieldDirty(control: AbstractControl) {
    if (
      (control.dirty && control.value !== '' && control.value !== null && control.value !== '-1') ||
      control.invalid
    ) {
      return true;
    }
    return false;
  }

  triggerValidation(frmGroup: UntypedFormGroup) {
    let pointsArr = [];

    for (const formName in frmGroup.controls) {
      if (formName.match(/^measuredPointsForm_(\d)*$/)) {
        pointsArr.push(frmGroup.controls[formName]);
      }

      for (let i = 0; i < pointsArr.length; i++) {
        if (
          pointsArr[i].status === 'INVALID' &&
          pointsArr[i].touched === false &&
          pointsArr[i].value[`measuring_${i}`] === '' &&
            pointsArr[i].value[`sourceId_${i}`] === '' &&
            pointsArr[i].value[`reportingInterval_${i}`] === -1 &&
            (pointsArr[i].value[`dataProvider_${i}`] === undefined || pointsArr[i].value[`dataProvider_${i}`] === '-1')
        ) {
          frmGroup.controls[formName].markAsUntouched();
        } else {
          frmGroup.controls[formName].markAllAsTouched();
        }

        if (
          pointsArr[i].status === 'INVALID' &&
          pointsArr[i].touched === true &&
          pointsArr[i].value[`measuring_${i}`] === '' &&
            pointsArr[i].value[`sourceId_${i}`] === '' &&
            pointsArr[i].value[`reportingInterval_${i}`] === -1 &&
            (pointsArr[i].value[`dataProvider_${i}`] === undefined || pointsArr[i].value[`dataProvider_${i}`] === '-1')
        ) {
          frmGroup.controls[formName].markAsUntouched();
        }

        var objDisplayLabels = pointsArr[i].value.displayLabels;

        if (
          pointsArr[i].value[`expressionFactor_${i}`] === null ||
          pointsArr[i].value[`multiplier_${i}`] === null ||
          Object.values(typeof objDisplayLabels !== 'undefined')[0] === ''
        ) {
          frmGroup.controls[formName].markAllAsTouched();
        }
      }
    }
  }

  isDisplayLabelDirty(frmGroup: UntypedFormGroup) {
    for (const ctrlValue in frmGroup['controls']) {
      if (ctrlValue.match(/^measuredPointsForm_(\d)*$/)) {
        frmGroup['controls'][ctrlValue]['controls'].displayLabels?.markAsTouched();
        frmGroup['controls'][ctrlValue]['controls'].displayLabels?.markAsDirty();
        frmGroup['controls'][ctrlValue]['controls'].displayLabels?.setErrors(null);
      }
    }
  }

  clearFormControlErrors(frmGroup: UntypedFormGroup) {
    Object.keys(frmGroup.controls).forEach(key => {
      const control: any = frmGroup.get(key);
      if (typeof control.controls === 'undefined') {
        control.setErrors(null);
        control.markAsUntouched({ onlySelf: true });
      } else {
        this.clearFormControlErrors(control);
      }
    });
  }
}
