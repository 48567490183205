import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Locale, LocaleBaseConfig } from '../model/locale.model';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable()
export class LocalesService {
  locales$: Subject<Locale[]>;

  constructor(private apiService: ApiService) {
    this.locales$ = new Subject<Locale[]>();
  }

  async setLocales() {
    const locales = await this.apiService.get('locales');
    this.locales$.next(convertKeys.toCamel(locales));
  }

  checkForLocale(locale_key: string, locales: Locale[]) {
    return locales.find(locale => locale.localeName === locale_key);
  }

  useSitesDefaultLocaleAndTimezone(site: any, equipment: any, multiLocaleConfig: LocaleBaseConfig) {
    const defaultTimezone = site.timezone || 'America/New_York';
    const defaultLocale = site.defaultLocale || 'en_US';
    equipment.timezone = defaultTimezone;
    this.updateSupportedLocales(defaultLocale, multiLocaleConfig);
    equipment.defaultLocale = defaultLocale;
  }

  private updateSupportedLocales(localeName: string, multiLocaleConfig: LocaleBaseConfig) {
    let locale = this.checkForLocale(localeName, multiLocaleConfig.supportedLocales);
    if (!locale) {
      let locale = this.checkForLocale(localeName, multiLocaleConfig.locales);
      multiLocaleConfig.supportedLocales = [...multiLocaleConfig.supportedLocales, locale];
    }
  }
}
