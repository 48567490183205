import { Component, Input, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Point } from '../../../shared/model/equipment.model';
import { MatSelectChange } from '@angular/material/select';
import { Formula } from '../../../shared/model/formula.model';
import { EquipmentModelsService } from '../../../shared/services/equipment-models.service';
import { EquipmentModel } from '../../../shared/model/equipment-model.model';

@Component({
  selector: 'app-formulas',
  templateUrl: './formulas.component.html',
  styleUrls: ['./formulas.component.scss', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormulasComponent implements OnDestroy {
  formParams = {};
  formulaId: string;
  loadingFormulas = true;
  loadingEquipmentModels = true;
  equipmentModels: EquipmentModel[];
  formulas: Formula[];
  subs: any[] = [];
  paramRules: object[];

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  @Input('mode') mode: string;
  @Input('appData') appData: any;
  @Input('point') point: Point;
  @Input('pointIndex') pointIndex: number;

  constructor(equipmentModelService: EquipmentModelsService) {
    const formulaSub = equipmentModelService.formulas$.subscribe(formulas => {
      this.formulas = JSON.parse(JSON.stringify(formulas));
      if (formulas.length > 0) {
        this.loadingFormulas = false;
      }
    });

    const equipModelsSub = equipmentModelService.equipmentModels$.subscribe(equipmentModels => {
      this.equipmentModels = equipmentModels;
      if (equipmentModels.length > 0) {
        this.loadingEquipmentModels = false;
      }
    });

    this.subs.push(formulaSub, equipModelsSub);
  }

  defaultParameters(parameters): void {
    if (parameters && this.point) {
      this.point.formulaParameters = new Map<string, number>();

      const parameterKeys = Object.keys(parameters);
      parameterKeys.forEach(param => {
        if (parameters[param].type === 'number') {
          this.point.formulaParameters[param] = parameters[param].default ? parameters[param].default : 0;
        } else if (parameters[param].type === 'boolean') {
          this.point.formulaParameters[param] = false;
        } else {
          this.point.formulaParameters[param] = '';
        }
      });
    }
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  onMeterTypeChange($event: MatSelectChange) {
    const foundFormula = this.formulas.find((formula: Formula) => {
      return formula.id === $event.value;
    });
    if (foundFormula) {
      this.defaultParameters(foundFormula.parameters);
    }
    if ($event.value == undefined) {
      delete this.point.formulaId;
      delete this.point.formulaParameters;
    }
  }

  getParameterRules(rules) {
    if (rules == undefined || rules.lenght <= 0) {
      return;
    }
    rules = rules.map(rule => {
      if (rule.targetParameter && !rule.targetParameter.includes('pointIndex') && this.pointIndex != undefined) {
        rule.targetParameter = `pointIndex${this.pointIndex}_${rule.targetParameter}`;
      }
      return rule;
    });
    return rules;
  }

  getTargetParameterName(param) {
    return param.split('_')[1];
  }

  checkForFormula(MeterTypeID) {
    if (!this.loadingFormulas) {
      const foundFormula = this.formulas.find((formula: Formula) => {
        return formula.id === MeterTypeID;
      });
      return !!foundFormula;
    }
    return false;
  }

  onFormulaChange($event: MatSelectChange) {
    return $event.value;
  }

  getFormula() {
    return this.formulaId;
  }

  getFormulaLabel() {
    if (this.formulas && this.point.formulaId) {
      let formula = this.formulas.find((formula: any) => formula.id === this.point.formulaId);
      return formula ? formula.formula : '';
    }
  }

  getFormulaDescription() {
    if (this.formulas && this.point.formulaId) {
      let formula = this.formulas.find((formula: any) => formula.id === this.point.formulaId);
      return formula ? formula.description : '';
    }
  }

  getFormulaParameters(): any {
    if (this.formulas && this.point.formulaId) {
      let formula = this.formulas.find((formula: any) => formula.id === this.point.formulaId);
      return formula ? formula.parameters : [];
    }
    return [];
  }

  determineInputType(value) {
    if (value.type === 'boolean') {
      return 'checkbox';
    }
    return value.type;
  }

  getEquipmentLabel() {
    if (this.formulas && this.point.formulaId) {
      let formula = this.formulas.find((formula: any) => formula.id === this.point.formulaId);
      return formula ? formula.id : '';
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
