import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EquipmentService } from '../shared/services/equipment.service';
import { EquipmentGroupService } from '../shared/services/equipment-group.service';
import { EquipmentDetailsService } from '../shared/services/equipment-details.service';
import { EquipmentGroup } from '../shared/model/equipment-group.model';
import { NavigationStart, Router } from '@angular/router';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { SidenavService } from '../shared/services/sidenav.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { EquipmentType } from '../shared/model/equipment.model';
import { TranslateService } from '@ngx-translate/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { NodeDefinition, TreeNode } from 'enel-tree';
import { filter } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['status', 'display_label', 'equipment_type'];
  dataSource = [];
  originalDataSource = [];
  flattenedDatasource = [];
  loading = true;
  noEquipment = false;
  selectedEquipment = '';
  selectedFilters = ['ALL'];
  groupData: EquipmentGroup[] = [];
  isInit = true;
  allFilter: any = {
    key: 'equipment.list.all_equipment',
  };
  equipmentTypes: EquipmentType[];

  views: any[] = [
    { value: 'equipment', label: 'equipment.equipment' },
    { value: 'group', label: 'equipment.group' },
  ];
  selectedView = this.views[0].value;

  expandedKeys = new Set<TreeNode<any>>();
  selectedKeys = new Set<TreeNode<any>>();

  selectNode = '';

  disabledNodeValues: string[] = ['Site'];
  disabledNodeDefinitions: NodeDefinition = {['spaceType'] : this.disabledNodeValues};
  ICON_DEFINITION: any = {
    SITE: 'SITE',
    EQUIPMENT_GROUP: 'EQUIPMENT_GROUP',
    BESS: 'BESS',
    CONTROL_SET: 'CONTROL_SET',
    GENERATOR: 'GENERATOR',
    MACHINERY: 'MACHINERY',
    METER: 'METER',
  };

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private orgSelectorService: ContextSelectorService,
    private organizationsService: OrganizationsService,
    private equipmentService: EquipmentService,
    private equipmentGroupService: EquipmentGroupService,
    private equipmentDetailsService: EquipmentDetailsService,
    private sidenavService: SidenavService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIcon('SITE', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/site.svg'));
    iconRegistry.addSvgIcon('EQUIPMENT_GROUP', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/equipmentgroup.svg'));
    iconRegistry.addSvgIcon('BESS', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/bess.svg'));
    iconRegistry.addSvgIcon('CONTROL_SET', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/controlset.svg'));
    iconRegistry.addSvgIcon('GENERATOR', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/generator.svg'));
    iconRegistry.addSvgIcon('MACHINERY', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/machinery.svg'));
    iconRegistry.addSvgIcon('METER', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/meter.svg'));

    //subscription for router events to update list after
    // deleting an equipment from View Component
    this.router.events
      .pipe(
        filter(value => value instanceof NavigationStart),
        filter((value: NavigationStart) => value.url == '/list'),
      )
      .subscribe(() => {
        const orgId = this.equipmentService.orgId$.getValue();
        this.equipmentService.getOrgEquipment(orgId);
      });

    this.loading = true;

    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.loading = true;
        this.equipmentGroupService.loadingList.next(true);
        this.equipmentService.orgEquipment$.next([]);
        await this.equipmentService.getOrgEquipment(org.id);
        await this.equipmentGroupService.getOrgEquipmentGroups(org.id);
        this.loading = false;
        this.equipmentGroupService.loadingList.next(false);
        if (!this.router.isActive('/', true) && !this.router.isActive('/create', true) && !this.isInit) {
          await this.router.navigate([`/`], {});
        }
        this.isInit = false;
      }
    });

    this.equipmentService.orgEquipment$.subscribe(async orgEquipment => {
      this.selectedFilters = ['ALL'];
      this.dataSource = [...orgEquipment];
      this.originalDataSource = orgEquipment;
      this.flattenedDatasource = this.flattenTree(orgEquipment);
      this.noEquipment = orgEquipment.length === 0;
      this.equipmentService.setEquipmentTypesDropDown();
      this.filterEquipment();
      if(this.selectedEquipment && orgEquipment.length > 0 && this.getNodeById(this.selectedEquipment)) {
        this.selectedKeys.clear();
        this.selectNode = this.selectedEquipment;
        this.selectedKeys.add(this.getNodeById(this.selectedEquipment));
        cdr.detectChanges();
      }
    });

    this.equipmentDetailsService.selectedEquipment.subscribe(equipmentId => {
      this.selectedEquipment = equipmentId;
      if(this.dataSource.length > 0 && this.getNodeById(equipmentId)) {
        this.selectNode = equipmentId;
        cdr.detectChanges();
      }
    });

    this.equipmentService.equipmentTypesDropDown$.subscribe(equipmentTypes => {
      this.equipmentTypes = equipmentTypes;
      this.allFilter.key = 'equipment.list.all_equipment';
      this.translateService.get(this.allFilter.key).subscribe((translatedDisplayLabel: string) => {
        this.allFilter = {
          name: 'ALL',
          displayLabel: translatedDisplayLabel,
          isSelected: false,
          key: 'equipment.list.all_equipment',
        };
      });
      this.equipmentTypes = [this.allFilter, ...this.equipmentTypes];
    });

    this.equipmentGroupService.equipmentGroups$.subscribe(equipmentGroups => {
      this.groupData = equipmentGroups;
      if(this.selectedEquipment && equipmentGroups.length > 0 && this.getNodeById(this.selectedEquipment)) {
        this.selectedKeys.clear();
        this.selectNode = this.selectedEquipment;
        this.selectedKeys.add(this.getNodeById(this.selectedEquipment));
        cdr.detectChanges();
      }
    });
  }

  async ngOnInit() {
    this.translateService.get(this.allFilter.key).subscribe((translatedDisplayLabel: string) => {
      this.allFilter = {
        name: 'ALL',
        displayLabel: translatedDisplayLabel,
        isSelected: false,
        key: 'equipment.list.all_equipment',
      };
    });
  }

  changeEquipmentFilter(event: MatOptionSelectionChange) {
    // the DOM will lazy load the equipmentTypes, must ensure they are there to filter with first...
    if (!event.isUserInput) {
      return;
    }

    if (event.source.selected) {
      if (event.source.value && event.source.value.includes('ALL')) {
        // reset all other options
        this.selectedFilters = ['ALL'];
      } else {
        // uncheck all option
        this.selectedFilters.push(event.source.value);
        this.selectedFilters = this.selectedFilters.filter(filter => filter != this.allFilter.name);
      }
    } else {
      this.selectedFilters = this.selectedFilters.filter(type => {
        return type !== event.source.value;
      });
      if (!this.selectedFilters.length) {
        this.selectedFilters = ['ALL'];
      }
    }
    this.filterEquipment();
  }

  getNodeById(id: string): TreeNode<any> {
    return this.flattenedDatasource.find(node => {
      return node.id == id;
    });
  }

  private flattenTree(tree: TreeNode<any>[]): TreeNode<any>[] {
    // reset the internal structures
    const flattenedTree: TreeNode<any>[] = [];
    // terminal condition tracker
    const unflattenedNodes: TreeNode<any>[] = [...tree];
    // temporary current node
    let node: TreeNode<any> | undefined;

    // iterate until all nodes have been looked at
    while (unflattenedNodes.length > 0) {
      node = unflattenedNodes.pop();
      if (node === undefined) continue;
      flattenedTree.push(node);
      if (node.children) {
        node.children.forEach((child: TreeNode<any>) => {
          child.parent = node.parent ? [...node.parent, node.id] : [node.id];
          unflattenedNodes.push(child);
        });
      }
    }
    return flattenedTree;
  }

  private filterEquipment() {
    if (this.selectedFilters.find(filter => filter === this.allFilter.name)) {
      this.dataSource = this.originalDataSource;
      return;
    }
    this.dataSource = JSON.parse(JSON.stringify(this.originalDataSource));
    this.dataSource.forEach(site => {
      if(!site.children) {
        return site;
      }
      site.children = this.filterThroughChildren(site);
      return site;
    })
  }

  filterThroughChildren(space: any) {
    if(!space.children) {
      return space;
    }
    let filteredChildren = [];
    space.children.forEach(child => {
      if(child.type == 'Equipment' && this.selectedFilters.find(filter => filter === child.equipmentType)) {
        filteredChildren.push(child);
      }
      else if(child.type == 'Equipmentgroup') {
        if(child.children) {
          filteredChildren.push({
            ...child,
            children: this.filterThroughChildren(child)});
        }
        else {
          filteredChildren.push(child);
        }
      }
    });
    return filteredChildren;
  }

  onSelect(nodes: TreeNode<any>[]) {
    if(nodes && nodes.length && nodes[0].id && !this.loading) {
      const selectedNode = nodes[0];
      this.sidenavService.closeIfMobile();
      if (selectedNode.coreSpaceType && selectedNode.coreSpaceType === 'EQUIPMENT') {
        this.router.navigate([`details/${selectedNode.id}/view`], { queryParams: { type: 'EQUIPMENT_GROUP' } });
      } else {
        this.router.navigate([`details/${selectedNode.id}/view`], {});
      }
    }
  }

  routeToCreatePage() {
    this.selectedKeys.clear();
    this.selectedEquipment = null;
    this.cdr.detectChanges();
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
