import { NodeDefinition } from 'enel-tree';

export interface NodeType {
  type: string;
  label: string;
  typeKey: string;
  pointType: string;
  iconDefinitions: any;
  disabledNodeTypes: NodeDefinition;
  resource: string;
}

export const ORG_ICON_DEFINITION: any = {
  AdminGroup: {
    name: 'stars',
    type: '',
    namespace: '',
  },
  Organization: {
    name: 'language',
    type: '',
    namespace: '',
  },
  Spacegroup: {
    name: 'folder',
    type: '',
    namespace: '',
  },
  Metergroup: {
    name: 'folder',
    type: '',
    namespace: '',
  },
  Site: {
    name: 'apartment',
    type: '',
    namespace: '',
  },
  Meter: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
};

export const PROGRAM_ICON_DEFINITION: any = {
  Program: {
    name: 'circle',
    type: '',
    namespace: '',
  },
  Portfolio: {
    name: 'topic',
    type: '',
    namespace: '',
  },
  Product: {
    name: 'topic',
    type: '',
    namespace: '',
  },
  Registration: {
    name: 'developer_board',
    type: '',
    namespace: '',
  },
};

export const NodeTypes = [
  {
    type: 'organization',
    label: 'trends.node_type.organization',
    typeKey: 'spaceType',
    pointType: 'MeasuredPoint',
    iconDefinitions: ORG_ICON_DEFINITION,
    disabledNodeTypes: { spaceType: [] },
    resource: 'TRENDS',
  },
  {
    type: 'program',
    label: 'trends.node_type.program',
    typeKey: 'drType',
    pointType: 'TelemetryPoint',
    iconDefinitions: PROGRAM_ICON_DEFINITION,
    disabledNodeTypes: { drType: [] },
    resource: 'DR_ENTITY_MANAGEMENT',
  },
];
