import { ApiService } from './api.service';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EquipmentGroup } from '../model/equipment-group.model';
import { AtlasPagesService } from 'atlas-pages-loader';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';
import * as convertKeys from 'convert-keys';

@Injectable()
export class EquipmentGroupService {
  readonly equipmentGroups$ = new BehaviorSubject<EquipmentGroup[]>(null);
  readonly fullEquipmentGroup$ = new Subject<EquipmentGroup>();
  readonly equipmentLength$ = new Subject<number>();
  readonly equipmentGroupNotConverted$ = new Subject<EquipmentGroup>();
  readonly atlasPagesService: AtlasPagesService;

  readonly loadingList = new Subject<boolean>();

  contextIds: string[];
  loadingSpaces = false;
  groupListResult: any;
  equipmentsSelected: any[];
  initialState: any[];
  removedItems: any[];
  addedItems: any[];
  constructor(private apiService: ApiService, private httpClient: HttpClient, private cookieService: CookieService) {
    this.atlasPagesService = new AtlasPagesService(
      cookieService.getMarketsMock(),
      cookieService.getEnocSession(),
      httpClient,
    );

    this.removedItems = [];
    this.addedItems = [];
  }

  async getEquipmentGroupList() {
    try {
      const groups = await this.apiService.get(`equipment-groups`);
      this.equipmentGroups$.next(groups.data);
    } catch (err) {
      console.log('Could not load equipment group list.', err);
    }
  }

  async getEquipmentGroupById(equipmentGroupId: string) {
    try {
      const equipmentGroup = await this.apiService.get(`equipment-groups/${equipmentGroupId}`);

      const dtoEquipmentGroup: any = convertKeys.toCamel(equipmentGroup);
      dtoEquipmentGroup.displayLabels = equipmentGroup.display_labels;

      const fullEquipmentGroup = {
        ...dtoEquipmentGroup,
      };

      this.fullEquipmentGroup$.next(fullEquipmentGroup);
      this.equipmentGroupNotConverted$.next(equipmentGroup);

      return fullEquipmentGroup;
    } catch (err) {
      console.log('Could not load equipmentGroup.', err);
    }
  }

  async createRelationships(spaceId: string, relatedId: string) {
    let dto = { space_id: spaceId, related_id: relatedId };
    return await this.apiService.post(`relationships`, dto);
  }
  async updateEquipmentGroup(equipmentGroup: any) {
    const id = equipmentGroup.id;

    const dto = {
      default_locale: equipmentGroup.defaultLocale,
      display_labels: equipmentGroup.displayLabels,
      status: equipmentGroup.status,
      timezone: equipmentGroup.timezone,
    };

    return this.apiService.put(`equipment-groups/${id}`, dto);
  }

  async deleteRelationships(spaceId: string, relatedId: string) {
    return this.apiService.delete(`relationships/${spaceId}/${relatedId}`);
  }

  async getEquipmentHierarchies() {
    return await this.atlasPagesService.getSpecificHierarchies(['equipment']);
  }

  async getOrgEquipmentGroups(orgId) {
    try {
      const FilterBy = { id: orgId, dr_type: 'ORGANIZATION' };
      const allOrgEquipmentGroups = await this.atlasPagesService.getSpecificHierarchies(
        ['equipmentgroup', 'equipment'],
        FilterBy,
      );
      let equipments = [];
      equipments = convertKeys.toCamel(allOrgEquipmentGroups);
      this.groupListResult = allOrgEquipmentGroups;
      this.equipmentGroups$.next(equipments);
    } catch (err) {
      console.log('Could not load equipment.', err);
    }
    this.loadingSpaces = false;
  }

  findAndReturnEquipmentGroups(spaceTypes: string[], coreSpaceType: string, obj: any, memo: any[]) {
    if (obj) {
      if (
        obj.space_type &&
        spaceTypes.includes(obj.space_type.toLowerCase()) &&
        obj.core_space_type.toLowerCase() === coreSpaceType.toLowerCase()
      ) {
        memo.push(obj);
        return;
      }

      if (obj.children) {
        for (const child of obj.children) {
          this.findAndReturnEquipmentGroups(spaceTypes, coreSpaceType, child, memo);
        }
      }
    }
  }

  getGroupListResult() {
    return this.groupListResult;
  }

  setInitialState(list) {
    if (list) {
      this.initialState = [...list];
    }
  }

  getInitailState() {
    return this.initialState;
  }

  async deleteEquipmentGroup(id: string) {
    const response = await this.apiService.delete(`equipment-groups/${id}`);
    return convertKeys.toCamel<any>(response);
  }

  async createEquipmentGroup(body: any) {
    const dto = {
      default_locale: body.defaultLocale,
      display_labels: body.displayLabels,
      timezone: body.timezone,
      status: body.status,
    };
    return await this.apiService.post(`equipment-groups`, dto);
  }

  setEquipmentsSelected(list: any[]) {
    this.equipmentsSelected = list;
    this.equipmentLength$.next(this.equipmentsSelected.length);
  }

  removeItemFromGroup(item) {
    if (!this.removedItems.includes(item)) {
      this.removedItems.push(item);
      this.addedItems = this.addedItems.filter(currentItem => currentItem.id !== item.id);
    }
  }

  addItemToGroup(item) {
    if (!this.addedItems.includes(item)) {
      this.addedItems.push(item);
      this.removedItems = this.removedItems.filter(currentItem => currentItem.id !== item.id);
    }
  }

  getRemovedItems() {
    return this.removedItems;
  }

  getAddedItems() {
    return this.addedItems;
  }

  getEquipmentsSelected() {
    return this.equipmentsSelected;
  }
  resetItemsList() {
    this.addedItems = [];
    this.removedItems = [];
  }
}
