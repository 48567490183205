<div class="app-equipment-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingEquipment">dashboard</mat-icon>
                    <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" data-automation="viewTitle" *ngIf="isEquipment">
                    <ng-container *ngIf="!loadingEquipment  && !loadingEquipmentGroup">{{ equipment?.displayLabel }}</ng-container>    
                    <ng-container *ngIf="loadingEquipment">{{ 'equipment.loading' | translate }}</ng-container>
                </h1>

                <h1 id="view-title" data-automation="viewEquipmentGroupTitle" *ngIf="isEquipmentGroup">
                    <ng-container *ngIf="!loadingEquipmentGroup && !loadingEquipment">{{ equipmentGroup.displayLabel }}</ng-container>
                    <ng-container *ngIf="loadingEquipmentGroup">{{ 'sharedFields.loading' | translate }}</ng-container>
                </h1>


                <p *ngIf="isEquipment && equipment" class="desktop-only subtitle" data-automation="subtitle">
                    <ng-container *ngIf="equipment.equipmentType === 'METER'">{{ 'equipment.view.meter.subtitle' | translate }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'CONTROL_SET'">{{ 'equipment.view.controlSet.subtitle' | translate }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'MACHINERY'">{{ 'equipment.view.machine.subtitle' | translate }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'BESS'">{{ 'equipment.view.bess.subtitle' | translate }}</ng-container>
                    <ng-container *ngIf="equipment.equipmentType === 'GENERATOR'">{{ 'equipment.view.generator.subtitle' | translate }}</ng-container>
                </p>

                <p *ngIf="isEquipmentGroup" class="desktop-only subtitle" data-automation="equipmentGroupSubtitle">
                    <ng-container> {{ 'equipmentGroup.view.subtitle' | translate }} </ng-container>
                </p>
            </div>
        </div>

        <div class="right-column" *ngIf="!loadingEquipment">
            <button class="icon-btn x-btn-primary"
                    aria-label="delete"
                    data-automation="equipDeleteButton"
                    (click)="openDeleteConfirmation()"
                    [disabled]="!equipment && !equipmentGroup">
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'equipment.delete.confirmation.delete' | translate }}</span>
            </button>

            <button
                    class="icon-btn x-btn-primary"
                    data-automation="equipEdit"
                    (click)="handleEdit()"
                    [disabled]="equipmentId === ''"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text">{{ 'equipment.view.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #view="ngForm" class="equipment-form" *ngIf="isEquipment && equipment">
        <div [ngSwitch]="equipmentType">
            <div *ngSwitchCase="'METER'">
                <app-meter [appData]="appData" [mode]="mode" [meter]="equipment" [LocaleConfig]="multiLocaleConfig"></app-meter>
            </div>
            <div *ngSwitchCase="'CONTROL_SET'">
                <app-control-set [appData]="appData" [mode]="mode" [controlSet]="equipment" [LocaleConfig]="multiLocaleConfig"></app-control-set>
            </div>
            <div *ngSwitchCase="'MACHINERY'">
                <app-machine [appData]="appData" [mode]="mode" [machine]="equipment" [LocaleConfig]="multiLocaleConfig"></app-machine>
            </div>
            <div *ngSwitchCase="'BESS'">
                <app-bess [appData]="appData" [mode]="mode" [bess]="equipment" [LocaleConfig]="multiLocaleConfig"></app-bess>
            </div>
            <div *ngSwitchCase="'GENERATOR'">
                <app-generator [appData]="appData" [mode]="mode" [generator]="equipment" [LocaleConfig]="multiLocaleConfig"></app-generator>
            </div>
            <div *ngSwitchDefault class="alert alert-warning">Equipment type not yet supported.</div>
        </div>
    </form>

    <form #view="ngForm" class="equipment-form" *ngIf="isEquipmentGroup">
        <app-equipment-group [appData]="appData" [mode]="mode" [equipmentGroup]="equipmentGroup" [LocaleConfig]="multiLocaleConfig"></app-equipment-group>
    </form>

</div>
