import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Site } from '../model/space.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseEquipment } from '../model/equipment.model';
import { EquipmentService } from './equipment.service';

@Injectable()
export class EquipmentDetailsService {
  loadingSites = false;
  readonly sites$: BehaviorSubject<Site[]>;
  readonly equipment$ = new Subject<BaseEquipment>();
  readonly selectedSiteId$ = new Subject<string>();
  readonly selectedEquipment = new Subject<string>();

  constructor(private apiService: ApiService, private equipmentService: EquipmentService) {
    this.sites$ = new BehaviorSubject<Site[]>([]);
    this.selectedSiteId$.subscribe((siteId: string) => {
      if (siteId) {
        // this.getEquipmentForSite(siteId);
      }
    });
  }

  selectSite(siteId: string): void {
    this.selectedSiteId$.next(siteId);
  }

  setEquipment(equipment: any): void {
    this.equipment$.next(equipment);
  }

  async getEquipmentDetails(equipmentId) {
    const equipment = await this.equipmentService.getEquipment(equipmentId);
    if (equipment) {
      this.setEquipment(equipment);
    }
  }

  async getSitesForOrg(orgId: string) {
    try {
      this.loadingSites = true;
      const sites = await this.apiService.get(`sites/${orgId}`);
      this.sites$.next(sites.map(convertKeys.toCamel));
      this.loadingSites = false;
    } catch (err) {
      console.log('Could not load Sites.', err);
      this.loadingSites = false;
      this.sites$.next([]);
    }
  }
}
