import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BaseEquipment } from '../../shared/model/equipment.model';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalesService } from '../../shared/services/locales.service';

@Component({
  selector: 'app-generator',
  templateUrl: './generator-details.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeneratorDetailsComponent implements OnInit {
  timezones: Timezone[];
  // multi locale config
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';
  siteIdToDisplay: string = '';

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  private _mode: string;
  private _generator: BaseEquipment;
  private _data: any;

  constructor(
    private timezonesService: TimezonesService,
    private translateService: TranslateService,
    private localesService: LocalesService,
  ) {
    this.timezones = this.timezonesService.timezones;

    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('generator.create.placeholder.name');
    this.descriptionLabel = this.translateService.instant('sharedFields.create.description');
    this.descriptionPlaceholder = this.descriptionLabel;

    const generator: Partial<BaseEquipment> = {
      siteId: '-1',
      defaultLocale: 'en_US',
      timezone: 'America/New_York',
      displayLabels: {},
      descriptions: {},
    };

    this.populateGenerator(generator);
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set generator(generator: BaseEquipment) {
    this._generator = generator;
    if (generator.site) {
      this._generator.siteId = this._generator.site.id;
    }
    this.buildSupportedLocales();
  }

  get generator() {
    return this._generator;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
    this.generator.defaultLocale = appData.userLocale;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  populateGenerator(generatorData) {
    this._generator = generatorData;
  }

  getTimezoneForGenerator() {
    if (this.timezones.length > 0) {
      return (
        this.timezones.find(timezone => this._generator.timezone.indexOf(timezone.timezoneName) !== -1).displayLabel ||
        new Timezone()
      );
    }

    return new Timezone();
  }

  getLocaleForGenerator() {
    if (this.multiLocaleConfig.locales.length > 0) {
      return (
        this.multiLocaleConfig.locales.find(locale => this._generator.defaultLocale.indexOf(locale.localeName) !== -1)
          .displayLabel || new Locale()
      );
    }

    return new Locale();
  }

  getSiteForGenerator() {
    if (this._generator) {
      return this._generator.site.displayLabel;
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this.generator.equipmentType) {
      const equipmentType = this._data.equipmentTypes.find(
        (e: any) => e.name.toUpperCase() === this.generator.equipmentType.toUpperCase(),
      );
      if (equipmentType) {
        return equipmentType.displayLabel;
      }
      return '';
    }
  }

  onSiteChange({ value: siteId }) {
    this._data.siteId = siteId;
    const selectedSite = this._data.sites.find(site => siteId === site.id);
    if (selectedSite) {
      this.localesService.useSitesDefaultLocaleAndTimezone(selectedSite, this.generator, this.multiLocaleConfig);
      this.siteIdToDisplay = selectedSite.displayLabel;
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this._data.equipment && this._data.equipment.displayLabels) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipment.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  ngOnInit() {}
}
