import { EquipmentGroup } from './../../shared/model/equipment-group.model';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Timezone } from '../../shared/model/timezone.model';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { ORG_ICON_DEFINITION } from '../../shared/model/nodeType.model';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentGroupService } from '../../shared/services/equipment-group.service';
import { EQUIPMENTGROUP_ICON_DEFINITION } from '../../shared/model/nodeType';
import { EquipmentService } from 'src/app/shared/services/equipment.service';

@Component({
  selector: 'app-equipment-group',
  templateUrl: './equipment-group.component.html',
  styleUrls: ['../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EquipmentGroupComponent implements OnInit {
  public expandedKeys: any[] = ['0', '1'];
  public selectedKeys: any[] = [];
  data: any[] = [];

  subscriptions = [];
  dataSource = [];

  _data: any;
  _equipmentGroup: EquipmentGroup;

  equipmentGroupId: string;

  equipmentGroupLabel: any[] = [];
  equipmentType: string = '';
  loadingEquipment = true;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  timezones: Timezone[];

  nodes: EquipmentGroup[] = [];
  iconDefinitions: any = { ...ORG_ICON_DEFINITION, ...EQUIPMENTGROUP_ICON_DEFINITION };

  constructor(
    private timezonesService: TimezonesService,
    private equipmentGroupsService: EquipmentGroupService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private equipmentService: EquipmentService,
  ) {
    this.timezones = this.timezonesService.timezones;

    this.route.params.subscribe(async params => {
      if (params.id) {
        const { id } = params;
        if (this.route.queryParams['value']['type'] && this.route.queryParams['value']['type'] === 'EQUIPMENT_GROUP') {
          this.equipmentGroupId = id;
          this.equipmentGroupsService.equipmentGroups$.subscribe(async equipmentGroups => {
            if (equipmentGroups && equipmentGroups.length > 0) {
              let finalResult = [];
              equipmentGroups.forEach(res => {
                this.findNodeById(res, this.equipmentGroupId, finalResult);
              });
              finalResult = finalResult.filter(element => !!element);
              this.dataSource = finalResult;
              this.loadingEquipment = false;
            }
          });
          this.equipmentType = this.translateService.instant('equipmentGroup.view.equipment_group');
        }
      }
    });
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  @Input()
  set equipmentGroup(equipmentGroup: EquipmentGroup) {
    this._equipmentGroup = equipmentGroup;
  }

  get equipmentGroup() {
    return this._equipmentGroup;
  }

  public hasChildren = (dataItem: any) => {
    if (!dataItem) return false;
    return dataItem.id === this.equipmentGroupId ? true : false;
  };
  public children = (dataitem: any): Observable<any[]> => of(dataitem.children);

  expandToSelected() {
    this.equipmentGroupLabel.forEach(node => {
      if (node.children && node.children.length > 0) {
        this.expandedKeys.push(node.id);
      }
    });
  }
  onSelectEquipment(dataItem) {
    let url = '';

    if (dataItem.coreSpaceType && dataItem.coreSpaceType === 'EQUIPMENT') {
      url = `details/${dataItem.id}/view`;
      setTimeout(() => {
        this.router.navigate([url], { queryParams: { type: 'EQUIPMENT_GROUP' } });
      }, 2000);
    } else {
      url = `details/${dataItem.id}/view`;
      setTimeout(() => {
        this.router.navigate([url], {});
      }, 2000);
    }
  }

  findNodeById(element, id, finalResult) {
    if (element.id == id) {
      finalResult.push(element);
      return;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.findNodeById(element.children[i], id, finalResult);
      }
      finalResult.push(result);
      return;
    }
    return null;
  }

  getLocaleForEquipmentGroup() {
    if (
      this.multiLocaleConfig.locales &&
      this.multiLocaleConfig.locales.length > 0 &&
      this._equipmentGroup &&
      this._equipmentGroup.defaultLocale
    ) {
      return (
        this.multiLocaleConfig.locales.find(
          locale => this._equipmentGroup.defaultLocale.indexOf(locale.localeName) !== -1,
        ) || new Locale()
      );
    }
    return new Locale();
  }
  getIconByType(event) {
    if (!event || !event.spaceType) {
      return '';
    }
    event =
      event.spaceType === 'Spacegroup' || event.spaceType === 'Equipmentgroup'
        ? event.spaceType.toLowerCase()
        : event.equipmentType.toLowerCase();
    event = event === 'BESS' ? 'Bess' : event;
    if (this.iconDefinitions[event] && this.iconDefinitions[event].name) {
      return this.iconDefinitions[event].name;
    }
    return '';
  }

  getTimezoneForEquipmentGroup() {
    if (this.timezones.length > 0 && this._equipmentGroup && this._equipmentGroup.timezone) {
      return (
        this.timezones.find(timezone => this._equipmentGroup.timezone.indexOf(timezone.timezoneName) !== -1) ||
        new Timezone()
      );
    }
    return new Timezone();
  }

  getDisplayLabelForEquipmentGroup() {
    if (this._equipmentGroup.displayLabel) {
      return this._equipmentGroup.displayLabel;
    }
  }

  getParentDispalyLabel() {
    if (this._equipmentGroup) {
      if (this._equipmentGroup.topology) {
        return this._equipmentGroup.topology[0].displayLabel;
      }
    }
  }

  getStatusForEquipmentGroup() {
    if (this._equipmentGroup) {
      if (this._equipmentGroup.status) {
        return this._equipmentGroup.status;
      }
    }
  }

  getDescriptionForEquipmentGroup() {
    if (this._equipmentGroup) {
      if (this._equipmentGroup.description) {
        return this._equipmentGroup.description;
      }
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this._data.equipment && this._data.equipment.displayLabels) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipment.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  ngOnInit(): void {}
}
