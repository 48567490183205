<ng-container ngModelGroup="formulaParameters" id="parameters" #formulaParameters="ngModelGroup">
    <ng-container ngModelGroup="formulaSection" id="formulaSection" [ngSwitch]="point.formulaId">
        <div class="form-entry">
            <mat-form-field
                *ngIf="(isCreateMode || isEditMode) && pointIndex != undefined"
                appearance="outline"
                floatLabel="always"
            >
                <mat-label>{{ 'measuredPoint.create.meter_type' | translate }}</mat-label>
                <mat-select
                    id="meterType_select_{{ pointIndex }}"
                    name="meterType_select_{{ pointIndex }}"
                    #meterType="ngModel"
                    class="create-select"
                    [ngClass]="{ failure: meterType.invalid && (meterType.dirty || meterType.touched) }"
                    [(ngModel)]="point.formulaId"
                    data-automation="meterTypeSelector"
                    (selectionChange)="onMeterTypeChange($event)"
                    [class.loading-field]="loadingEquipmentModels"
                    appSelectValid
                    placeholder="{{ 'measuredPoint.create.select_meter_type' | translate }}"
                >
                    <mat-option>{{ 'measuredPoint.create.none_meter_type' | translate }}</mat-option>
                    <ng-container *ngFor="let equipmentModel of equipmentModels">
                        <mat-option
                            *ngIf="checkForFormula(equipmentModel.id)"
                            [value]="equipmentModel.id"
                            id="meterType_{{ equipmentModel.id }}"
                            >{{ equipmentModel.displayLabel }}</mat-option
                        >
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="meterType.invalid && (meterType.dirty || meterType.touched)">
                    <mat-error *ngIf="meterType.errors.selectValid">
                        <span data-automation="alertRequired">
                            {{ 'equipment.validation.required' | translate }}
                        </span>
                    </mat-error>
                </mat-error>
                <mat-hint *ngIf="loadingEquipmentModels" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipmentModels" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>

            <ng-container *ngIf="isViewMode">
                <mat-label class="info-title">{{ 'measuredPoint.create.meter_type' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="meterType_value">{{
                    getEquipmentLabel()
                        ? getEquipmentLabel()
                        : ('measuredPoint.create.placeholder.formula_description' | translate)
                }}</mat-label>
            </ng-container>
        </div>

        <div class="form-entry form-entry-column formula-description" *ngIf="point.formulaId">
            <mat-label
                >{{ 'measuredPoint.create.formula_label' | translate }}
                <mat-icon
                    class="formula-tooltip-icon"
                    [matTooltipPosition]="'right'"
                    matTooltipClass="formula-description"
                    matTooltip="{{ getFormulaDescription() }}"
                >
                    info
                </mat-icon>
            </mat-label>
            <div data-automation="formulaLabel">
                {{ getFormulaLabel() }}<span title="{{ getFormulaDescription() }}"></span>
            </div>
        </div>

        <ng-container *ngFor="let parameter of getFormulaParameters() | keyvalue; let parameterInd = index">
            <div class="form-entry">
                <mat-form-field
                    *ngIf="(isCreateMode || isEditMode) && point.formulaParameters[parameter.key] != undefined"
                    appearance="outline"
                    floatLabel="always"
                >
                    <mat-label>{{ parameter.value.displayLabel }} (P{{ parameterInd + 1 }})</mat-label>
                    <input
                        matInput
                        formulaRules
                        [rules]="getParameterRules(parameter.value.rules)"
                        id="parameter_{{ pointIndex }}_{{ parameterInd }}"
                        step=".01"
                        #parameterIndex="ngModel"
                        [type]="determineInputType(parameter.value)"
                        [ngClass]="{
                            failure: parameterIndex.invalid && (parameterIndex.dirty || parameterIndex.touched)
                        }"
                        name="pointIndex{{ pointIndex }}_{{ parameter.key }}"
                        placeholder="{{ parameter.value.displayLabel }} (P{{ parameterInd + 1 }})"
                        [(ngModel)]="point.formulaParameters[parameter.key]"
                        data-automation="parameter"
                        data-url-persist
                        [required]="parameter.value.required"
                    />

                    <mat-error *ngIf="parameterIndex.invalid && (parameterIndex.dirty || parameterIndex.touched)">
                        <mat-error *ngIf="parameterIndex.errors.required">
                            <span data-automation="validationError">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>

                        <mat-error *ngIf="parameterIndex.errors.gt?.isValid">
                            <span class="input-title rules"></span>
                            <span data-automation="validationError">
                                {{ 'equipment.validation.greater_than_rule' | translate }}
                                {{ getTargetParameterName(parameterIndex.errors.gt.targetParam) }}
                            </span>
                        </mat-error>

                        <mat-error *ngIf="parameterIndex.errors.gte?.isValid">
                            <span class="input-title rules"></span>
                            <span data-automation="validationError">
                                {{ 'equipment.validation.greater_than_equal_rule' | translate }}
                                {{ getTargetParameterName(parameterIndex.errors.gte.targetParam) }}
                            </span>
                        </mat-error>

                        <mat-error *ngIf="parameterIndex.errors.lt?.isValid">
                            <span class="input-title rules"></span>
                            <span data-automation="validationError">
                                {{ 'equipment.validation.less_than_rule' | translate }}
                                {{ getTargetParameterName(parameterIndex.errors.lt.targetParam) }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ parameter.value.displayLabel }}:</mat-label>
                    <mat-label class="info-value" [attr.data-automation]="parameter.value.name">
                        {{ point.formulaParameters[parameter.key] }}
                    </mat-label>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
