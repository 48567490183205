import { NodeDefinition } from 'enel-tree';

export interface NodeType {
  type: string;
  label: string;
  typeKey: string;
  pointType: string;
  iconDefinitions: any;
  disabledNodeTypes: NodeDefinition;
  resource: string;
}

export const EQUIPMENTGROUP_ICON_DEFINITION: any = {
  spacegroup: {
    name: 'folder',
    type: '',
    namespace: '',
  },
  equipmentgroup: {
    name: 'folder',
    type: '',
    namespace: '',
  },
  meter: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  bess: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  control_set: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  generator: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  machinery: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  evcs: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
};

export const NodeTypes = [
  {
    type: 'equipmentGroup',
    label: '',
    typeKey: 'spaceType',
    pointType: '',
    iconDefinitions: EQUIPMENTGROUP_ICON_DEFINITION,
    disabledNodeTypes: { spaceType: [] },
    resource: 'EQUIPMENT',
  },
];
