import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Rule } from '../model/formula.model';

@Directive({
  selector: '[formulaRules]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FormulaRulesValidDirective,
      multi: true,
    },
  ],
})
export class FormulaRulesValidDirective implements Validator {
  @Input('rules') rules: Rule[];

  validate(control: AbstractControl) {
    if (this.rules && this.rules.length && control.value) {
      for (let rule of this.rules) {
        const paramGroup = control.parent;
        let valid = true;
        const targetParam = paramGroup.get(rule.targetParameter);
        const targetValue = targetParam ? targetParam.value : '';
        let parseTargetValue = parseFloat(targetValue);
        let parseCurrentValue = parseFloat(control.value);
        switch (rule.comparator) {
          case 'gt':
            valid = parseCurrentValue > parseTargetValue;
            break;
          case 'gte':
            valid = parseCurrentValue >= parseTargetValue;
            break;
          case 'lt':
            valid = parseCurrentValue < parseTargetValue;
            break;
          case 'lte':
            valid = parseCurrentValue <= parseTargetValue;
            break;
          case 'eq':
            valid = parseCurrentValue == parseTargetValue;
            break;
        }
        if (!valid) {
          let returnError = {};
          returnError[rule.comparator] = { isValid: true, targetParam: rule.targetParameter?.toUpperCase() };
          return returnError;
        } else {
          if (!targetParam.valid) {
            targetParam.markAsDirty();
            targetParam.updateValueAndValidity();
          }
        }
      }
    }
    return null;
  }
}
