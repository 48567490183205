import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { EquipmentType, Device } from '../model/equipment.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AtlasPagesService } from 'atlas-pages-loader';
import { Site } from '../model/space.model';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { Router } from '@angular/router';

const EQUIPMENTS_SITES_HIERARCHY = ['organization', 'spacegroup', 'site', 'equipment'];

const EQUIPMENT_WHITELIST = ['METER', 'CONTROL_SET', 'MACHINERY', 'BESS', 'GENERATOR'];

@Injectable()
export class EquipmentService {
  readonly equipmentTypesDropDown$: BehaviorSubject<EquipmentType[]>;
  readonly equipmentTypes$: BehaviorSubject<EquipmentType[]>;
  readonly pointTypes$: BehaviorSubject<any[]>;
  readonly controlTypes$: BehaviorSubject<any[]>;
  readonly siteMeters$: BehaviorSubject<any[]>;
  readonly siteMachines$: BehaviorSubject<any[]>;
  readonly sites$: Subject<Site[]>;
  readonly devices$: BehaviorSubject<Device[]>;
  readonly orgEquipment$: BehaviorSubject<any[]>;
  readonly allSpaces$: BehaviorSubject<any>;
  readonly orgId$ = new BehaviorSubject<string>(null);
  readonly orgSites$ = new BehaviorSubject<any[]>([]);
  readonly sitesEquipments$ = new BehaviorSubject<any[]>([]);
  readonly selectedType$ = new BehaviorSubject<string>(null);

  readonly loadEquipmentsGroup$ = new Subject<boolean>();

  loadSiteEquipments = false;
  siteToLoad = '';
  equipmentWhitelistDropdown = ['meter', 'machinery', 'control_set', 'bess', 'generator', 'equipment_group'];
  equipmentWhiteListEquipmentTypes = ['meter', 'machinery', 'control_set', 'control_element', 'bess', 'generator'];
  cookieService: CookieService;
  ngxCookieService: NgxCookieService;
  orgHierarchyService: AtlasPagesService;
  siteHierarchyService: AtlasPagesService;
  atlasPageLoader: AtlasPagesService;
  router: Router;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    cookieService: CookieService,
    ngxCookieService: NgxCookieService,
  ) {
    this.equipmentTypesDropDown$ = new BehaviorSubject<EquipmentType[]>([]);
    this.equipmentTypes$ = new BehaviorSubject<EquipmentType[]>([]);
    this.sites$ = new Subject<Site[]>();
    this.pointTypes$ = new BehaviorSubject<any[]>([]);
    this.controlTypes$ = new BehaviorSubject<any[]>([]);
    this.siteMeters$ = new BehaviorSubject<any[]>([]);
    this.devices$ = new BehaviorSubject<Device[]>([]);
    this.siteMachines$ = new BehaviorSubject<any[]>([]);
    this.orgEquipment$ = new BehaviorSubject<any[]>([]);
    this.allSpaces$ = new BehaviorSubject<any[]>([]);

    this.ngxCookieService = ngxCookieService;
    this.cookieService = cookieService;
    this.orgHierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
    this.siteHierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async setEquipmentTypesDropDown() {
    try {
      let equipmentTypesDropDown = await this.apiService.get(`equipment-types`);
      equipmentTypesDropDown = equipmentTypesDropDown
        .filter(({ name }: any) => this.equipmentWhitelistDropdown.includes(name.toLowerCase()))
        .map(type => {
          type.name = type.name.toUpperCase();
          return type;
        });

      this.equipmentTypesDropDown$.next(equipmentTypesDropDown.map(convertKeys.toCamel));
    } catch (err) {
      console.log('Could not load equipment types for dropdown.', err);
    }
  }

  async setEquipmentTypes() {
    try {
      let equipmentTypes = await this.apiService.get(`equipment-types`);
      equipmentTypes = equipmentTypes.filter(({ name }: any) =>
        this.equipmentWhiteListEquipmentTypes.includes(name.toLowerCase()),
      );
      this.equipmentTypes$.next(equipmentTypes.map(convertKeys.toCamel));
    } catch (err) {
      console.log('Could not load equipment types.', err);
    }
  }

  async getEquipment(id: string) {
    try {
      const equipment = await this.apiService.get(`equipment/${id}`);
      if (equipment.alternate_ids && equipment.alternate_ids.ADDRESS) {
        equipment.address = equipment.alternate_ids.ADDRESS;
      }
      const dto: any = convertKeys.toCamel(equipment);

      dto.displayLabels = equipment.display_labels;
      if (equipment.descriptions) {
        dto.descriptions = equipment.descriptions;
      }

      if (dto.site) {
        dto.site.displayLabels = equipment.site.display_labels;
      }

      if (equipment.points) {
        equipment.points.forEach((point, index) => {
          dto.points[index].displayLabels = point.display_labels;
        });
      }

      if (equipment.control_elements) {
        equipment.control_elements.forEach((controlElement, index) => {
          dto.controlElements[index].displayLabels = controlElement.display_labels;
          dto.controlElements[index].descriptions = controlElement.descriptions;
        });
      }

      if (!dto.hasOwnProperty('descriptions')) {
        dto.descriptions = {};
      }

      return dto;
    } catch (err) {
      console.log('Could not load equipment.', err);
    }
  }

  ForceReloadNoCached() {
    this.orgHierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
    this.siteHierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async refetchEquipment() {
    if (this.orgId$.value) {
      await this.getOrgEquipment(this.orgId$.value);
    }
  }

  async createEquipment(equipment: any): Promise<any> {
    const endpoint = equipment.equipmentType.toLowerCase().replace('_', '-');
    const dto = convertKeys.toSnake<any>({
      ...equipment,
      default_locale: equipment.defaultLocale,
    });
    dto.display_labels = equipment.displayLabels;
    dto.descriptions = equipment.descriptions;
    if (equipment.points) {
      equipment.points.forEach((point, index) => {
        dto.points[index].display_labels = point.displayLabels;
        dto.points[index].formula_parameters = point.formulaParameters;
      });
    }

    if (equipment.controlElements) {
      equipment.controlElements.forEach((controlElement, index) => {
        dto.control_elements[index].display_labels = controlElement.displayLabels;
        dto.control_elements[index].descriptions = controlElement.descriptions;
      });
    }

    const response = await this.apiService.post(`equipment/${endpoint}`, dto);
    return convertKeys.toCamel<any>(response);
  }

  async deleteEquipment(id: string, type: string) {
    const response = await this.apiService.delete(`equipment/${id}?type=${type}`);
    if (response?.successes?.some(element => element?.id === id)) {
      this.orgEquipment$.next(this.orgEquipment$.getValue().filter(equipment => equipment.id !== id));
    }
    return response;
  }

  async updateEquipment(equipment: any) {
    const endpoint = equipment.equipmentType.toLowerCase().replace('_', '-');
    const id = equipment.id;
    const dto = convertKeys.toSnake<any>(equipment);
    dto.display_labels = equipment.displayLabels;
    dto.descriptions = equipment.descriptions;
    if (equipment.points) {
      equipment.points.forEach((point, index) => {
        dto.points[index].display_labels = point.displayLabels;
        dto.points[index].formula_parameters = point.formulaParameters;
      });
    }

    if (equipment.controlElements) {
      equipment.controlElements.forEach((controlElement, index) => {
        dto.control_elements[index].display_labels = controlElement.displayLabels;
        dto.control_elements[index].descriptions = controlElement.descriptions;
      });
    }

    const updatedEquipment = await this.apiService.put(`equipment/${endpoint}/${id}`, dto);
    return convertKeys.toCamel<any>(updatedEquipment);
  }

  async setPointTypes() {
    try {
      const pointTypes = await this.apiService.get('point-types');
      this.pointTypes$.next(convertKeys.toCamel(pointTypes));
    } catch (err) {
      console.log('Could not load point types.', err);
    }
  }

  async setDevicesAndCommands() {
    try {
      const devices = await this.apiService.get('available-commands');
      this.devices$.next(convertKeys.toCamel(devices));
    } catch (err) {
      console.log('Could not fetch devices and commands');
    }
  }

  async setControlTypes() {
    try {
      const controlTypes = await this.apiService.get('control-types');
      const controlTypesArr = Object.values(controlTypes);

      this.controlTypes$.next(convertKeys.toCamel(controlTypesArr));
    } catch (err) {
      console.log('Could not load reporting intervals.', err);
    }
  }

  async setOrgEquipment(orgId: string) {
    let sites: any = [];
    let meters: any = [];
    let machines: any = [];
    try {
      const orgEquipment = await this.apiService.get(`organizations/${orgId}/equipment`);
      if (orgEquipment) {
        sites = orgEquipment.filter(space => {
          let siteMeters = {};
          let siteMachines = {};

          if (space.equipment && Object.keys(space.equipment).length > 0) {
            siteMeters[space.id] = space.equipment.METER || [];
            siteMeters[space.id] = Object.values(siteMeters[space.id]).map(meter => convertKeys.toCamel(meter));

            siteMachines[space.id] = [
              ...(space.equipment.MACHINERY || []),
              ...(space.equipment.BESS || []),
              ...(space.equipment.GENERATOR || []),
            ];
            siteMachines[space.id] = Object.values(siteMachines[space.id]).map(machine => convertKeys.toCamel(machine));

            if (siteMachines[space.id]) {
              machines[space.id] = siteMachines[space.id].reduce((group, equip) => {
                group[equip.spaceType] = [...(group[equip.spaceType] || []), equip];
                return group;
              }, {});
            }
          }

          meters = { ...meters, ...siteMeters };
          return space.space_type === 'Site';
        });

        this.sites$.next(convertKeys.toCamel(sites));

        if (meters) {
          this.siteMeters$.next(meters);
        }

        if (machines) {
          this.siteMachines$.next(machines);
        }
      }
    } catch (err) {
      console.log('Could not load equipment.', err);
    }
  }
  getEquipmentFromNode(node, memo) {
    if (node.equipment_type) {
      memo.push(node);
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => {
        this.getEquipmentFromNode(child, memo);
      });
    }
  }

  filterEquipment(space: any) {
    if(!space.children) {
      return space;
    }
    let filteredChildren = [];
    space.children.forEach(child => {
      if(child.type == 'Equipment' && EQUIPMENT_WHITELIST.includes(child.equipment_type.toUpperCase())) {
        delete child.children;
        const newChild = {
          ...child,
          equipment_type:
            child.equipment_type === 'ControlSet' ? 'CONTROL_SET' : child.equipment_type.toUpperCase(),
        };
        filteredChildren.push(newChild);
      }
      else if(child.type == 'Equipmentgroup') {
        const newChild = {
          ...child,
          equipment_type: 'EQUIPMENT_GROUP'
        }
        if(child.children) {
          filteredChildren.push({
            ...newChild,
            children: this.filterEquipment(newChild)});
        }
        else {
          filteredChildren.push(newChild);
        }
      }
    });
    return filteredChildren;
  }

  async getOrgEquipment(orgId: string) {
    try {
      const FilterBy = { id: orgId, dr_type: 'ORGANIZATION' };
      const allOrgsSiteHierarchies = await this.orgHierarchyService.getSpecificHierarchies(['site','equipmentgroup','equipment'], FilterBy);
      const filteredOrgsSiteHierarchies = allOrgsSiteHierarchies.map(site => {
        if(!site.children) {
          site.equipment_type = 'SITE';
          return site;
        }
        site.children = this.filterEquipment(site);
        site.equipment_type = 'SITE';
        return site;
      })

      this.allSpaces$.next(filteredOrgsSiteHierarchies);
      const siteEquipments: any[] = convertKeys.toCamel(filteredOrgsSiteHierarchies);
      this.orgId$.next(orgId);
      if (this.loadSiteEquipments) {
        this.getEquipmentSpaceFromSites(this.siteToLoad);
        this.loadSiteEquipments = false;
      }
      this.orgEquipment$.next(siteEquipments);
    } catch (err) {
      console.log('Could not load equipment.', err);
    }
  }

  async getOrgSites(orgId: string) {
    try {
      const FilterBy = { id: orgId, dr_type: 'ORGANIZATION' };
      const allOrgsSites = await this.orgHierarchyService.getSpecificHierarchies(['site'], FilterBy);
      const allOrgsGroups = await this.orgHierarchyService.getSpecificHierarchies(['spacegroup'], FilterBy);
      this.orgSites$.next(convertKeys.toCamel([...allOrgsSites, ...allOrgsGroups]));
    } catch (err) {
      console.log('Could not load sites.', err);
    }
  }

  setToloadEquipmentSites(siteId) {
    this.siteToLoad = siteId;
    this.loadSiteEquipments = true;
  }

  async getEquipmentSpaceFromSites(siteId) {
    try {
      const orgId = this.orgId$.value;
      const FilterBy = { id: orgId, dr_type: 'ORGANIZATION' };
      this.ForceReloadNoCached();
      const allOrgsEquipments = await this.orgHierarchyService.getSpecificHierarchies(
        EQUIPMENTS_SITES_HIERARCHY,
        FilterBy,
      );
      const currentOrgEquipments = allOrgsEquipments.filter(org => org.id === orgId)[0].children;
      let spaceEquipmentGroups = [];
      let allOrgEquipmentGroups = [];
      if (currentOrgEquipments) {
        currentOrgEquipments.forEach(element => {
          spaceEquipmentGroups = [];
          this.findAndReturnEquipmentGroups(siteId, element, spaceEquipmentGroups);
          allOrgEquipmentGroups = [...allOrgEquipmentGroups, ...spaceEquipmentGroups];
        });
      }
      this.sitesEquipments$.next(convertKeys.toCamel(allOrgEquipmentGroups[0]?.children));
    } catch (err) {
      console.log('Could not load Equipments.', err);
    }
  }

  findAndReturnEquipmentGroups(siteID: string[], obj: any, memo: any[]) {
    if (obj) {
      if (obj.id === siteID) {
        memo.push(obj);
        return;
      }

      if (obj.children) {
        for (const child of obj.children) {
          this.findAndReturnEquipmentGroups(siteID, child, memo);
        }
      }
    }
  }
}
