<div id="group-form" ngModelGroup="groupForm">
    <div class="form-wrapper">
        <ng-container *ngIf="isCreateMode || isEditMode">
            <!-- Equipment type -->
            <div class="form-entry equipment-type" *ngIf="isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'equipment.create.equipment_type' | translate }}</mat-label>
                    <mat-select
                        id="equipmentType"
                        name="equipmentType"
                        #equipmentType="ngModel"
                        [ngClass]="{
                            failure: equipmentType.invalid && (equipmentType.dirty || equipmentType.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="this.group.equipmentType"
                        data-automation="equipmentType"
                        data-url-persist
                        [disabled]="isEditMode"
                        placeholder="{{ getEquipmentTypeDisplayLabel() }}"
                    >
                        <mat-option
                            *ngFor="let equipType of _data.equipmentTypesDropDown"
                            [value]="equipType.name"
                            id="equipType_{{ equipType.name }}"
                        >
                            {{ equipType.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- name -->
            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="group.displayLabels"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="'group_display_labels'"
                    data-automation="Equipment Group Name"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <!--- supported locales --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.supported_locales' | translate }}</mat-label>
                    <mat-select
                        id="supportedLocale"
                        name="supportedLocale"
                        #supportedLocale="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched)
                        }"
                        [(ngModel)]="multiLocaleConfig.supportedLocales"
                        (selectionChange)="handleSelectionChange()"
                        placeholder="{{ 'sharedFields.create.placeholder.supported_locales' | translate }}"
                        data-automation="Supported Locales"
                        required
                        multiple
                    >
                        <mat-option
                            *ngFor="let locale of multiLocaleConfig.locales"
                            [disabled]="limitLocaleSelectList(5, locale)"
                            [value]="locale"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- locale -->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>
                        {{ 'sharedFields.create.default_locale' | translate }}
                    </mat-label>
                    <mat-select
                        id="locale"
                        name="locale"
                        #locale="ngModel"
                        [(ngModel)]="group.defaultLocale"
                        (selectionChange)="handleSelectionChange()"
                        data-automation="Default Locale"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.supported_locales' | translate }}"
                    >
                        <mat-option
                            *ngFor="let locale of multiLocaleConfig.supportedLocales"
                            [value]="locale.localeName"
                            [disabled]="limitLocaleSelectList(5, locale)"
                        >
                            {{ locale.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- timezone -->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.timezone' | translate }}</mat-label>
                    <mat-select
                        id="timezone"
                        name="timezone"
                        #timezone="ngModel"
                        [ngClass]="{
                            failure: timezone.invalid && (timezone.dirty || timezone.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="group.timezone"
                        data-automation="Timezone"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.timezone_selector' | translate }}"
                    >
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                            timezone.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="timezone.invalid">
                        <mat-error *ngIf="timezone.errors.pattern">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- parent -->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-spinner *ngIf="loadingParents" matPrefix diameter="15"></mat-spinner>
                    <mat-label>{{ 'sharedFields.create.parent' | translate }}</mat-label>
                    <mat-select
                        id="parent"
                        name="parent"
                        #parent="ngModel"
                        [class.loading-field]="_data.loadingParent"
                        [ngClass]="{
                            failure: parent.invalid && (parent.dirty || parent.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="group.parentId"
                        [disabled]="!noEquipmentSelected || selectedEquipments() || loadingEquipments"
                        data-automation="Parent"
                        required
                        (selectionChange)="handleSelectionChangeSites($event)"
                        placeholder="{{ 'group.placeholder.parent' | translate }}"
                    >
                        <mat-option
                            *ngFor="let parent of parentsDropDown"
                            [value]="parent.id"
                            id="parent_{{ parent.id }}"
                        >
                            {{ parent.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- status -->
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.status' | translate }}</mat-label>
                    <mat-select
                        id="status"
                        name="status"
                        #status="ngModel"
                        required
                        [ngClass]="{
                            failure: status.invalid && (status.dirty || status.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="this.group.status"
                        data-automation="Status"
                        data-url-persist
                        placeholder="{{ 'group.placeholder.status' | translate }}"
                    >
                        <mat-option *ngFor="let st of statusesDropdown" [value]="st.name" id="status_{{ st.name }}">
                            {{ st.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>

    <ng-container>
        <h3 data-automation="viewTitle" class="points-title">
            {{ 'group.equipment.title' | translate }}
        </h3>
        <hr class="form-divider" />
        <div *ngIf="loadingEquipments" class="btn-spinner">
            <mat-spinner class="equipments-spinner" diameter="40"></mat-spinner>
        </div>
        <ng-container *ngIf="(noEquipmentsToShow() && !loadingEquipments) || !group.parentId">
            <div class="list-empty list-empty-equipment" data-autmation="no-equipment-text">
                {{ 'equipment.list.table.group.no_equipment' | translate }}
            </div>
        </ng-container>
        <app-equipment-list
            [filter]="true"
            [sort]="true"
            [source]="equipmentsSourceList"
            [equipmentgroup]="equipmentgroup"
            [(destination)]="equipmentsDestinationList"
            (movedItem)="isMovedItem($event)"
            height="200px"
            *ngIf="!noEquipmentsToShow() && !loadingEquipments && group.parentId"
        >
        </app-equipment-list>
    </ng-container>
</div>
